import { Fragment, FunctionComponent } from "react";
import { shallowEqual } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "../hooks";

const ToolbarContextContainer: FunctionComponent = () => {
  return (
    <ToastContainer
      position="bottom-center"
      limit={1}
      autoClose={false}
      hideProgressBar
      closeButton={false}
      closeOnClick={false}
      draggableDirection="y"
      pauseOnFocusLoss={false}
      draggable={false}
      style={{
        width: "100%",
        height: "100%",
        padding: 0,
        bottom: 0,
        userSelect: "none",
      }}
    />
  );
};

const ErrorContainer: FunctionComponent = () => {
  return (
    <ToastContainer
      position="top-center"
      limit={1}
      hideProgressBar
      closeButton={false}
      draggableDirection="y"
      pauseOnFocusLoss={false}
      style={{ width: "100%", padding: 0, top: 0 }}
    />
  );
};

const AppToastContainer: FunctionComponent = () => {
  const floatingButton = useAppSelector(
    (state) => state.app.floatingButton,
    shallowEqual
  );

  const renderComponent = (floatingButton: string) => {
    switch (floatingButton) {
      case "Home":
        return null;
      case "Category":
        return <ToolbarContextContainer />;
      case "Search":
        return <ToolbarContextContainer />;
      case "Product":
        return <ErrorContainer />;
      case "Cart":
        return <ErrorContainer />;
      case "OrderConfirmation":
        return <ErrorContainer />;
      default:
        return null;
    }
  };

  return <Fragment>{renderComponent(floatingButton)}</Fragment>;
};

export default AppToastContainer;
