import { css } from "@emotion/react";
import { FunctionComponent } from "react";

type TitleProps = {
  noMargin?: boolean;
};

const Title: FunctionComponent<TitleProps> = ({ children, noMargin }) => {
  return (
    <h3
      css={css`
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        color: var(--color-black);

        ${!noMargin &&
        css`
          margin-bottom: 13px;
        `}
      `}
    >
      {children}
    </h3>
  );
};

export default Title;
