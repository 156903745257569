import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const dummyjson = require("dummy-json");

interface AppState {
  store: Store;
  customer: Customer;
  floatingButton: string;
  navigation: Array<NavigationItem>;
  isLoading: boolean;
  lastUpdated: number | undefined;
  invertTransition: boolean;
  deliveryInfo: string;
}

const dummyJsonTemplate = `{
  "collection": [
    {{#repeat min=500 max=1000}}
    {
      "id": {{@index}},
      "type": "product",
      "status": {{boolean}},
      "visibility": {{int 0 1}},
      "storeId": 0,
      "name": "{{lorem min=5 max=10}}",
      "price": {{float 3 39 '0.00'}},
      "description": "{{lorem min=7 max=15}}",
      "category": {{int 0 5}},
      "thumbnail":  "https://picsum.photos/id/{{@index}}/120/120",
      "image": "https://picsum.photos/id/{{@index}}/600/600",
      "createdAt": {{int 2999999999999 5999999999999}},
      "attributes": [
        {
          "id": 0,
          "title": "Escolha um molho:",
          "maxSelected": 1,
          "required": true,
          "options": [
            {
              "id": 0,
              "name": "Molho vermelho",
              "qty": 0,
              "maxQty": 1,
              "price": 0,
              "attribute": 0
            },
            {
              "id": 1,
              "name": "Molho holandês",
              "qty": 0,
              "maxQty": 1,
              "price": 0,
              "attribute": 0
            },
            {
              "id": 2,
              "name": "Molho velouté",
              "qty": 0,
              "maxQty": 1,
              "price": 2,
              "attribute": 0
            },
            {
              "id": 3,
              "name": "Molho bechamel",
              "qty": 0,
              "maxQty": 1,
              "price": 2,
              "attribute": 0
            }
          ]
        }
      ], 
      "sides": [
        {{#repeat min=0 max=5}}
        {
          "catalogId": {{@index}},
          "qtyMax": {{int 0 30}}
        }
        {{/repeat}}
      ],
      "ingredients": [
        {{#repeat min=0 max=5}}
        {
          "id": {{@index}},
          "name": "{{lorem min=1 max=3}}"
        }
        {{/repeat}}
      ]
    }
    {{/repeat}}
  ]
}`;
const dummyJsonResult = dummyjson.parse(dummyJsonTemplate);
const dummyJson = JSON.parse(dummyJsonResult); // Returns a string

export const result = {
  store: {
    id: 1234,
    deliveryInfo: "DeliveryInfo",
    name: "Restaurante Belavilla",
    openAt: "08:30",
    closeAt: "22:00",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    image: "https://i.pinimg.com/originals/0d/6d/f3/0d6df30ba4594d1fcadbda4ba608519e.png",
  },
  collection: dummyJson.collection,
  categories: [
    { name: "Pratos", id: 0, image: undefined },
    { name: "Hamburgers", id: 1, image: undefined },
    { name: "Bebidas", id: 2, image: undefined },
    { name: "Sobremesas", id: 3, image: undefined },
    {
      name: "Pizzas",
      id: 4,
      image: undefined,
    },
    {
      name: "Ofertas",
      id: 5,
      image: "https://www.registrarcorp.com/wp-content/uploads/2021/02/Amazon-logo.png",
    },
  ],
};

const initialState: AppState = {
  store: { id: undefined, image: "", name: "", openAt: "", closeAt: "", description: "" },
  customer: { name: "", phone: "" },
  floatingButton: "OpenCartOverlay",
  navigation: [],
  isLoading: true,
  lastUpdated: undefined,
  invertTransition: false,
  deliveryInfo: "",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDeliveryInfo: (state, action: PayloadAction<string>) => {
      state.deliveryInfo = action.payload;
    },
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
    setFloatingButton: (state, action: PayloadAction<string>) => {
      state.floatingButton = action.payload;
    },
    openOverlay: (state, action: PayloadAction<NavigationItem>) => {
      const alreadyOpened = state.navigation.find((nav) => {
        const sameComponent = nav.component === action.payload.component;
        const sameUrl = nav.url === action.payload.url;
        return sameComponent && sameUrl;
      });
      if (!alreadyOpened) state.navigation.push(action.payload);
    },
    closeOverlay: (state, action: PayloadAction<NavigationItem | undefined>) => {
      if (action.payload) {
        state.navigation = state.navigation.filter((nav) => {
          const sameComponent = action.payload && nav.component !== action.payload.component;
          const sameUrl = action.payload && nav.url !== action.payload.url;
          return sameComponent && sameUrl;
        });
      } else {
        state.navigation.pop();
      }
    },
    clearOverlayHistory: (state) => {
      // Remain the current overlay only
      state.navigation = [state.navigation[state.navigation.length - 1]];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLastUpdated: (state, action: PayloadAction<number>) => {
      state.lastUpdated = action.payload;
    },
    setStore: (state, action: PayloadAction<Store>) => {
      state.store = action.payload;
    },
    setInvertTransition: (state, action: PayloadAction<boolean>) => {
      state.invertTransition = action.payload;
    },
  },
});

export const { setDeliveryInfo, setCustomer, setFloatingButton, openOverlay, closeOverlay, clearOverlayHistory, setLoading, setLastUpdated, setStore, setInvertTransition } = appSlice.actions;

export default appSlice.reducer;

// Definitions
export type NavigationItem = {
  component: string;
  url: string;
};

export type Customer = {
  name: string;
  phone: string;
};

export interface Store {
  id: number | undefined;
  name: string | undefined;
  description: string;
  openAt: string;
  closeAt: string;
  image: string | undefined;
}

interface CssValues {
  top: string | number;
  right: string | number;
  bottom: string | number;
  left: string | number;
}

// App helpers
export const isNumber = (value: any) => {
  return typeof value === "number" && isFinite(value);
};
export const getCssValue = (v: number | string | undefined) => {
  if (!v) return 0;
  return typeof v === "string" ? v : `${v}px`;
};
export const parseCssValues: (str: string) => CssValues = (str) => {
  const pieces = str.split(" ");
  const length = pieces.length;
  var top = 0;
  var right = 0;
  var bottom = 0;
  var left = 0;

  if (length === 1) {
    top = right = bottom = left = parseFloat(pieces[0]);
  }
  if (length === 2) {
    top = bottom = parseFloat(pieces[0]);
    right = left = parseFloat(pieces[1]);
  }
  if (length === 3) {
    top = parseFloat(pieces[0]);
    right = left = parseFloat(pieces[1]);
    bottom = parseFloat(pieces[2]);
  }
  if (length === 4) {
    top = parseFloat(pieces[0]);
    right = parseFloat(pieces[1]);
    bottom = parseFloat(pieces[2]);
    left = parseFloat(pieces[3]);
  }

  return {
    top,
    right,
    bottom,
    left,
  };
};

// Vars
export const inputHeight = 45;
