import { css } from "@emotion/react";

const _vars = {
  colorBlue: "#225C86",
  colorBlack: "#000000",
  borderRadiusOverlay: 21,
};

const vars = css`
  :root {
    --header-height: 44px;
    --border-radius-overlay: ${_vars.borderRadiusOverlay}px;
    --color-primary: ${_vars.colorBlue};
    --color-black: ${_vars.colorBlack};
    --color-line: #e5e5e5;
    --color-divider: #f5f5f5;
    --color-blue-line: #225880;
    --color-dark-gray: #5e6770;
    --color-red: #e74c3c;
    --color-bg: #f7f7f7;
    --color-placeholder: #d5d7d9;
    --sidebar-bg: #e7e7e7;
    --sidebar-tab: #f4f4f4;
  }
`;

export default vars;
