import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import dummyjson from "dummy-json";
import { CartItem } from "./cart";

interface CatalogState {
  activeCategory: number;
  activeProduct: CartItem | undefined;
  categories: Array<Category>;
  collection: Array<Catalog>;
  searchQuery: string;
  sortBy: string;
}

// Initial state
const initialState: CatalogState = {
  activeCategory: 0,
  activeProduct: undefined,
  categories: [],
  // collection: dummyJson.collection,
  collection: [],
  searchQuery: "",
  sortBy: "NameAsc",
};

// Slice
export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setActiveCategory: (state, action: PayloadAction<number>) => {
      state.activeCategory = action.payload;
    },
    setActiveProduct: (state, action: PayloadAction<CartItem>) => {
      state.activeProduct = action.payload;
    },
    setCollection: (state, action: PayloadAction<Catalog[]>) => {
      state.collection = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setQty: (state, action: PayloadAction<number>) => {
      if (state.activeProduct) state.activeProduct.qty = action.payload;
    },
    setAttr: (state, action: PayloadAction<Attribute>) => {
      if (state.activeProduct) {
        const attrIndex = state.activeProduct.attributes?.findIndex((attr) => attr.id === action.payload.id);
        if (typeof attrIndex !== "undefined" && attrIndex > -1 && state.activeProduct.attributes) {
          state.activeProduct.attributes[attrIndex] = action.payload;
        }
      }
    },
    setOption: (state, action: PayloadAction<AttributeOption>) => {
      if (state.activeProduct) {
        const attrIndex = state.activeProduct.attributes?.findIndex((attr) => attr.id === action.payload.attribute);
        if (typeof attrIndex !== "undefined" && attrIndex > -1 && state.activeProduct.attributes) {
          const attr = state.activeProduct.attributes[attrIndex];
          const isMultiselect = attr.maxSelected > 1 || attr.maxSelected === -1;
          const optionIndex = attr.options.findIndex((opt) => opt.name === action.payload.name);
          if (typeof optionIndex !== "undefined" && optionIndex > -1) {
            // Reset options if not multiselect
            if (!isMultiselect) {
              attr.options = attr.options.map((opt) => {
                opt.qty = 0;
                return opt;
              });
            }
            // Update option
            attr.options[optionIndex] = action.payload;
          }
        }
      }
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setActiveCategory, setActiveProduct, setCollection, setCategories, setQty, setOption, setAttr, setSearchQuery, setSortBy } = catalogSlice.actions;

export default catalogSlice.reducer;

// Definitions

export type Catalog = {
  name: string;
  type: string;
  sides: Side[];
  price: number;
  status: boolean;
  storeId: number;
  visibility: number;
  description: string;
  id: number | undefined;
  attributes: Attribute[];
  createdAt: number | string;
  category: number | undefined;
  image: string | undefined | null;
  thumbnail: string | undefined | null;
  ingredients: Ingredient[];
};

export type Attribute = {
  id: number;
  title: string;
  required: boolean;
  maxSelected: number;
  options: AttributeOption[];
};

export type AttributeOption = {
  id: number;
  qty: number;
  name: string;
  price: number;
  attribute: number;
};

export type Side = {
  qtyMax: number;
  catalogId: number;
};

export type Category = {
  id: number;
  name: string;
  image: string | undefined | null;
};

export type Ingredient = {
  id: number;
  name: string;
};

// Helpers
export function formatCurrency(price: number) {
  return price.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export function calculatePrice(product: CartItem, onlyOne?: boolean, sides?: CartItem[]) {
  return (
    (onlyOne ? 1 : product.qty) *
      (product.price +
        (product.attributes
          ? product.attributes.reduce((a, attr) => {
              const attrSum = attr.options.reduce((a, opt) => {
                if (opt.qty > 0) {
                  const optionTotals = opt.qty * opt.price;
                  return a + optionTotals;
                }
                return a;
              }, 0);
              return a + attrSum;
            }, 0)
          : 0)) +
    (sides
      ? sides.reduce((a, item) => {
          return a + item.price * item.qty;
        }, 0)
      : 0)
  );
}

// Generate dummy collection
// const dummyJsonTemplate = `{
//   "collection": [
//     {{#repeat min=500 max=1000}}
//     {
//       "id": {{@index}},
//       "type": "product",
//       "name": "{{lorem min=5 max=10}}",
//       "price": {{float 3 39 '0.00'}},
//       "description": "{{lorem min=7 max=15}}",
//       "category": {{int 0 5}},
//       "thumbnail":  "/images/thumbnail{{@index}}.jpg",
//       "image": "/images/image/{{@index}}.jpg",
//       "attributes": [
//         {
//           "name": "molho",
//           "title": "Escolha um molho:",
//           "maxSelected": 1,
//           "required": true,
//           "options": [
//             {
//               "name": "Molho vermelho",
//               "qty": 0,
//               "maxQty": 1,
//               "price": 0,
//               "attribute": "molho"
//             },
//             {
//               "name": "Molho holandês",
//               "qty": 0,
//               "maxQty": 1,
//               "price": 0,
//               "attribute": "molho"
//             },
//             {
//               "name": "Molho velouté",
//               "qty": 0,
//               "maxQty": 1,
//               "price": 2,
//               "attribute": "molho"
//             },
//             {
//               "name": "Molho mechamel",
//               "qty": 0,
//               "maxQty": 1,
//               "price": 2,
//               "attribute": "molho"
//             }
//           ]
//         },
//         {
//           "name": "acompanhamento",
//           "title": "Acompanhamentos:",
//           "maxSelected": -1,
//           "required": false,
//           "options": [
//             {
//               "name": "Batata rústica",
//               "qty": 0,
//               "maxQty": 10,
//               "price": 7,
//               "attribute": "acompanhamento"
//             },
//             {
//               "name": "Onion rings",
//               "qty": 0,
//               "maxQty": 10,
//               "price": 7,
//               "attribute": "acompanhamento"
//             },
//             {
//               "name": "Nuggets (10un)",
//               "qty": 0,
//               "maxQty": 10,
//               "price": 10,
//               "attribute": "acompanhamento"
//             }
//           ]
//         }
//       ]
//     }
//     {{/repeat}}
//   ]
// }`;
// const dummyJsonResult = dummyjson.parse(dummyJsonTemplate);
// const dummyJson = JSON.parse(dummyJsonResult); // Returns a string
