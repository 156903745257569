import { css } from "@emotion/react";
import { FC } from "react";

const Row: FC = ({ children, ...props }) => {
  return (
    <div
      css={css`
        display: inline-flex;
        width: 100%;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Row;
