import { css } from "@emotion/react";
import { Fragment, FunctionComponent } from "react";
import { useAppDispatch } from "../hooks";
import { closeOverlay, openOverlay } from "../store/slices/app";
import FavoriteButton from "./FavoriteButton";

type OverlayHeadingProps = {
  title?: string;
  favoriteButton?: boolean;
  hideIcon?: boolean;
  hideBackButton?: boolean;
  onBack?: () => void;
};

const OverlayHeading: FunctionComponent<OverlayHeadingProps> = ({
  children,
  title,
  favoriteButton,
  hideIcon,
  hideBackButton,
  onBack,
}) => {
  const dispatch = useAppDispatch();
  return (
    <div
      css={css`
        // Overlay Heading
        border-bottom: 1px solid var(--color-line);
        padding: 20px 20px 0;
      `}
    >
      <div
        css={css`
          // Upper title bar wrapper
          display: flex;
          align-items: center;
          margin-bottom: 18px;
          min-height: 25px;

          ${!hideIcon &&
          css`
            justify-content: space-between;
          `}
        `}
      >
        {!hideBackButton && (
          <div
            css={css`
              // Back icon
            `}
            onClick={() => {
              dispatch(closeOverlay());

              // On back callback
              if (onBack) onBack();
            }}
          >
            <svg
              width="26"
              height="25"
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.22218 11.2222C0.792606 11.6518 0.792606 12.3482 1.22218 12.7778L8.22254 19.7782C8.65212 20.2077 9.3486 20.2077 9.77817 19.7782C10.2078 19.3486 10.2078 18.6521 9.77817 18.2225L3.55564 12L9.77817 5.77746C10.2078 5.34788 10.2078 4.6514 9.77818 4.22182C9.3486 3.79225 8.65212 3.79225 8.22254 4.22182L1.22218 11.2222ZM24 10.9L2 10.9L2 13.1L24 13.1L24 10.9Z"
                fill="#225C86"
              />
            </svg>
          </div>
        )}
        <h1
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 15px;
            font-weight: 900;
            line-height: 14px;
            letter-spacing: -0.5px;
            padding-left: 11px;
            max-height: 25px;
            flex: 1;

            ${hideBackButton &&
            css`
              padding-left: 25px;
            `}

            ${hideIcon &&
            css`
              padding-right: 25px;
            `}
          `}
        >
          {title && title}
        </h1>
        {!hideIcon && (
          <Fragment>
            {favoriteButton && <FavoriteButton />}
            {!favoriteButton && (
              <div
                css={css`
                  // Search icon
                `}
                onClick={() => {
                  dispatch(
                    openOverlay({
                      url: `/search`,
                      component: "Search",
                    })
                  );
                }}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.89583 3.125C11.6916 3.125 13.4138 3.83835 14.6835 5.10813C15.9533 6.37791 16.6667 8.1001 16.6667 9.89583C16.6667 11.5729 16.0521 13.1146 15.0417 14.3021L15.3229 14.5833H16.1458L21.3542 19.7917L19.7917 21.3542L14.5833 16.1458V15.3229L14.3021 15.0417C13.1146 16.0521 11.5729 16.6667 9.89583 16.6667C8.1001 16.6667 6.37791 15.9533 5.10813 14.6835C3.83835 13.4138 3.125 11.6916 3.125 9.89583C3.125 8.1001 3.83835 6.37791 5.10813 5.10813C6.37791 3.83835 8.1001 3.125 9.89583 3.125ZM9.89583 5.20833C7.29167 5.20833 5.20833 7.29167 5.20833 9.89583C5.20833 12.5 7.29167 14.5833 9.89583 14.5833C12.5 14.5833 14.5833 12.5 14.5833 9.89583C14.5833 7.29167 12.5 5.20833 9.89583 5.20833Z"
                    fill="#225C86"
                  />
                </svg>
              </div>
            )}
          </Fragment>
        )}
      </div>
      {children}
    </div>
  );
};

export default OverlayHeading;
