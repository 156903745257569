import { css } from "@emotion/react";
import { FC } from "react";

type LabelProps = {};

const Label: FC<LabelProps> = ({ children, ...props }) => {
  return (
    <div
      css={css`
        color: var(--color-black);
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 8px;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Label;
