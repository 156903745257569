import { css } from "@emotion/react";
import { FC, FocusEventHandler, forwardRef } from "react";
import { inputHeight, isNumber, parseCssValues } from "../store/slices/app";
import ErrorMessage from "./Input/ErrorMessage";

type InputProps = {
  title?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  setValue?: (value: string) => void;
  padding?: string;
  ref?: React.ForwardedRef<any>;
  validation?: string[];
  resetValidation?: () => void;
  textarea?: boolean;
  textAlignCenter?: boolean;
  step?: number;
  onFocus?: FocusEventHandler | undefined;
  onBlur?: () => void;
  onKeyDown?: () => void;
  onClick?: () => void;
};

const Input: FC<InputProps> = forwardRef(
  ({ title, placeholder, type, value, setValue, padding, validation, resetValidation, textarea, textAlignCenter, step, onFocus, onBlur, onKeyDown, ...props }, ref) => {
    const inputPadding = padding ? parseCssValues(padding) : false;
    const hasErrors = validation?.length;
    const inputCss = css`
      width: 100%;
      height: ${inputHeight}px;
      padding: 20px 15px;
      transition: all 0.3s ease-in-out;
      font-size: 16px;

      background-color: #fff;
      border: 1px solid var(--color-line);
      padding: 14px 18px;
      border-radius: 8px;

      &:autofill {
        filter: grayscale(100%);
      }

      &:focus {
        outline: none;
        border-color: #a7a7a7;
      }

      ${textAlignCenter &&
      css`
        text-align: center;
      `}

      // Padding - Add extra space for icons
  ${inputPadding &&
      Object.entries(inputPadding).map((v: any) => {
        return parseFloat(v[1])
          ? css`
          padding-${v[0]}: ${v[1]}${isNumber(v[1]) ? "px" : ""};
    `
          : "";
      })}

  // With error
  ${hasErrors &&
      css`
        /* color: var(--color-red); */
        border-color: var(--color-red);
      `}


    // Number
    ${type === "number" &&
      css`
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          -moz-appearance: textfield;
        }
      `}
    `;

    return (
      <Wrapper {...props}>
        <div
          css={css`
            position: relative;
            width: 100%;

            ${!textarea &&
            css`
              height: ${inputHeight}px;
            `}
          `}
        >
          {type === "password" && <PasswordVisibilityToggler inputRef={ref} />}
          {!textarea ? (
            <input
              ref={ref}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                if (setValue) {
                  setValue(e.target.value);
                }
              }}
              onFocus={(e) => {
                if (resetValidation) resetValidation();
                if (onFocus) onFocus(e);
              }}
              onBlur={() => {
                if (onBlur) onBlur();
              }}
              onKeyDown={onKeyDown}
              type={type}
              step={step}
              css={css`
                ${inputCss}
              `}
            />
          ) : (
            <textarea
              ref={ref}
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                if (setValue) {
                  setValue(e.target.value);
                }
              }}
              onFocus={(e) => {
                if (resetValidation) resetValidation();
                if (onFocus) onFocus(e);
              }}
              onBlur={() => {
                if (onBlur) onBlur();
              }}
              onKeyDown={onKeyDown}
              css={css`
                ${inputCss}

                height: ${2.5 * inputHeight}px;
                resize: none;
              `}
            />
          )}
        </div>
        {hasErrors ? <ErrorMessage>{validation?.join("")}</ErrorMessage> : !1}
      </Wrapper>
    );
  }
);

const Wrapper: FC = ({ children, ...props }) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

const PasswordVisibilityToggler: FC<{
  inputRef: any;
}> = ({ inputRef }) => {
  return (
    <div
      onClick={() => {
        const type = inputRef.current.type;
        const isText = type === "text";
        inputRef.current.type = isText ? "password" : "text";
      }}
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        height: ${inputHeight}px;
        width: ${inputHeight}px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.01573 5.05141L3.01587 4.05908L16.0802 17.1234L15.0879 18.1235L12.6813 15.717C11.7827 16.0139 10.8295 16.1701 9.82932 16.1701C5.92253 16.1701 2.58613 13.7401 1.23438 10.31C1.77351 8.93476 2.63301 7.72366 3.72691 6.76258L2.01573 5.05141ZM9.82932 7.96588C10.451 7.96588 11.0472 8.21284 11.4868 8.65244C11.9264 9.09204 12.1734 9.68827 12.1734 10.31C12.1734 10.5834 12.1265 10.8491 12.0406 11.0913L9.04796 8.09871C9.29018 8.01276 9.55585 7.96588 9.82932 7.96588ZM9.82932 4.44976C13.7361 4.44976 17.0725 6.87979 18.4243 10.31C17.7836 11.9352 16.6975 13.3416 15.2988 14.3652L14.1893 13.2479C15.252 12.5134 16.1271 11.5132 16.7209 10.31C15.4317 7.68459 12.7672 6.01248 9.82932 6.01248C8.97764 6.01248 8.14159 6.15312 7.36023 6.40316L6.15694 5.20768C7.28209 4.72324 8.52445 4.44976 9.82932 4.44976ZM2.93774 10.31C4.22698 12.9353 6.89141 14.6074 9.82932 14.6074C10.3685 14.6074 10.8998 14.5527 11.392 14.4433L9.61054 12.654C8.4932 12.5368 7.60245 11.6461 7.48524 10.5287L4.82863 7.8643C4.05508 8.52845 3.40655 9.3567 2.93774 10.31Z"
          fill="#E1E1E1"
        />
      </svg>
    </div>
  );
};

export default Input;
