import { css } from "@emotion/react";
import { FC } from "react";

const ErrorMessage: FC = ({ children, ...props }) => {
  return (
    <div
      css={css`
        margin: 7px 0 3px 0;
        font-size: 13px;
        color: var(--color-red);
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default ErrorMessage;
