import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "..";
import validation, { rules } from "../validation";
import { isNumber } from "./app";

interface ValidationState {
  data: { [key: string]: Validation };
}

const initialState: ValidationState = {
  data: validation,
};

export const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    setValidation: (state, action: PayloadAction<{ name: string; validation: Validation }>) => {
      const entries = Object.entries(state.data);
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry[0] === action.payload.name) {
          state.data[entry[0]] = action.payload.validation;
        }
      }
    },
    resetValidation: (state, action: PayloadAction<string>) => {
      const path = action.payload.split(".");
      if (path.length === 1) {
        state.data[action.payload] = validation[action.payload];
      } else {
        state.data[path[0]][path[1]] = validation[path[0]][path[1]];
      }
    },
  },
});

export const { setValidation, resetValidation } = validationSlice.actions;

export default validationSlice.reducer;

// Definitions
export type Validation = { [key: string]: string[] };

export type ValidationRule = {
  input: string;
  isRequired?: boolean;
  minLength?: number | boolean;
  maxLength?: number | boolean;
};

// Helpers
const test = (value: any, rule: string) => {
  const isObject = typeof value === "object";
  const isBoolean = typeof value === "boolean";

  if (rule === "required") {
    if (!value && !isNumber(value)) return false;
    if (!isObject && !value.length && !isNumber(value) && !isBoolean) return false;
  }
  return true;
};

export const validate =
  (name: string, data: any): AppThunk =>
  (dispatch, getState) => {
    // Reset validation state
    dispatch(resetValidation(name));
    var hasErrors = false;
    var validation: Validation = {};

    Object.entries(data).forEach((entry: any) => {
      const input = entry[0];
      const value = entry[1];

      const rule = rules[name]?.find((r: ValidationRule) => r.input === input);
      if (rule) {
        const { isRequired } = rule;
        validation[input] = [];
        if (isRequired && !test(value, "required")) {
          validation[input].push("Campo Obrigatório. ");
          hasErrors = true;
        }
      }
    });

    dispatch(setValidation({ name, validation }));

    return !hasErrors;
  };
