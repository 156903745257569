import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appReducer from "./slices/app";
import catalogReducer from "./slices/catalog";
import cartReducer from "./slices/cart";
import orderConfirmationReducer from "./slices/orderConfirmation";
import validationReducer from "./slices/validation";

const store = configureStore({
  reducer: {
    app: appReducer,
    catalog: catalogReducer,
    cart: cartReducer,
    validation: validationReducer,
    orderConfirmation: orderConfirmationReducer,
  },
});
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
