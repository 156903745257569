import { css } from "@emotion/react";
import axios from "axios";
import { Fragment, FunctionComponent } from "react";
import { shallowEqual } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button } from "../FloatingButton";
import { useAppDispatch, useAppSelector } from "../hooks";
import api from "../store/api";
import { clearOverlayHistory, closeOverlay, openOverlay } from "../store/slices/app";
import { removeAllItems, setOrderConfirmation } from "../store/slices/cart";
import { setSubmittedAt } from "../store/slices/orderConfirmation";
import { validate } from "../store/slices/validation";

const PlaceOrder: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => state.cart.items);
  const store = useAppSelector((state) => state.app.store);
  const deliveryInfo = useAppSelector((state) => state.app.deliveryInfo, shallowEqual);
  const customer = useAppSelector((state) => state.app.customer, shallowEqual);

  const makeRequest = () => {
    const submittedAt = new Date().getTime();

    // Set submitted at
    dispatch(setSubmittedAt(submittedAt));

    setTimeout(() => {
      dispatch(
        setOrderConfirmation({
          id: randomId(),
          store,
          createdAt: new Date().getTime(),
          items: [...items],
        })
      );
      // Clear cart
      dispatch(removeAllItems());
      // Clear navigation history
      dispatch(clearOverlayHistory());

      // Open Cart overlay
      dispatch(
        openOverlay({
          component: "OrderConfirmation",
          url: `/order/0`,
        })
      );
    }, 1000);

    return;

    // axios
    //   .post(`${api.baseUrl}${api.orderNew}`, {
    //     storeId: store.id,
    //     deliveryInfo,
    //     customer,
    //     items,
    //   })
    //   .then((r) => {
    //     // Set order confirmation data
    //   })
    //   .catch((error) => {
    //     console.log(error);

    //     // Close overlay
    //     setTimeout(() => {
    //       toast.error(`Houve um erro na sua solicitação por favor tente novamente. ${error}`, {
    //         transition: Slide,
    //         autoClose: 5000,
    //         style: {
    //           minHeight: 75,
    //           borderRadius: 0,
    //         },
    //       });
    //       dispatch(
    //         closeOverlay({
    //           component: "OrderConfirmation",
    //           url: `/order/0`,
    //         })
    //       );
    //     }, 1000);
    //   });
  };

  return (
    <Fragment>
      <Button>
        <div
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
          `}
          onClick={() => {
            const info = {
              customerName: customer.name,
              customerPhone: customer.phone,
              deliveryInfo: deliveryInfo,
            };

            // Validation
            // @ts-ignore
            if (dispatch(validate("infoReview", info))) {
              makeRequest();
            }
          }}
        >
          <div
            css={css`
              flex: 1;
              text-align: center;
              display: flex;
              justify-content: center;
            `}
          >
            <div
              css={css`
                font-weight: bold;
                font-size: 15px;
                line-height: 18px;
                margin-right: 10px;
              `}
            >
              Confirmar
            </div>
          </div>
        </div>
      </Button>
    </Fragment>
  );
};

const randomId = () => Math.floor(Math.random() * (Math.floor(1009999) - Math.ceil(1000000))) + Math.ceil(1000000);

export default PlaceOrder;
