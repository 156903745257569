import { css } from "@emotion/react";
import { FunctionComponent } from "react";
import Img from "../App/Img";
import Placeholder from "../App/Placeholder";
import { useAppDispatch, useAppSelector } from "../hooks";
import { openOverlay } from "../store/slices/app";
import { setActiveCategory } from "../store/slices/catalog";

type CategoriesProps = {
  isLoading: boolean;
};

const Categories: FunctionComponent<CategoriesProps> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.catalog.categories);
  const categoriesRow = new Array(Math.round(categories.length / 2))
    .fill(undefined)
    .map((_, index) => {
      let arr = [categories[index * 2]];
      if (categories[index * 2 + 1]) arr.push(categories[index * 2 + 1]);
      return arr;
    });

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <div
      css={css`
        padding: 0 20px;
      `}
    >
      {categoriesRow.map((row, index) => (
        <div
          css={css`
            display: flex;
          `}
          key={index}
        >
          {row.map(({ name, id, image }, index) => (
            <div
              css={css`
                width: calc(50% - 10px);
                float: left;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                padding: 14px;
                min-height: 60px;
                margin: 5px;
                background-color: #ffffff;
                border: 1px solid var(--color-line);
                border-radius: 12px;
                user-select: none;

                img {
                  max-width: 100%;
                  max-height: 60px;
                  margin: -14px;
                }
              `}
              key={id}
              onClick={(e) => {
                console.log(`Clicked category ID ${id}`);

                // Set active category
                dispatch(setActiveCategory(id));

                // Open category overlay
                dispatch(
                  openOverlay({
                    component: "Category",
                    url: `/category/${id}`,
                  })
                );
              }}
            >
              {image ? <Img src={image} alt={name} spinner /> : name}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const LoadingSkeleton: FunctionComponent = () => {
  return (
    <Wrapper>
      {Array(6)
        .fill(undefined)
        .map((_, index) => (
          <Placeholder
            key={index}
            width={"calc(50% - 10px)"}
            height={60}
            marginBottom={7}
            borderRadius={12}
            margin={5}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper: FunctionComponent = ({ children }) => {
  return (
    <div
      css={css`
        padding: 0 20px;
      `}
    >
      {children}
    </div>
  );
};

export default Categories;
