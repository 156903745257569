import { FC, Fragment, FunctionComponent, SyntheticEvent } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Scrollbars } from "react-custom-scrollbars";
import Modal from "../App/Modal";

// Components
import Heading from "./Heading";
import Title from "../App/Title";
import { CartItem, updateItem, setItemToRemove, removeItem } from "../store/slices/cart";
import { closeOverlay, openOverlay } from "../store/slices/app";
import { Attribute, calculatePrice, formatCurrency, Ingredient, setActiveProduct } from "../store/slices/catalog";
import Img from "../App/Img";

const Cart: FunctionComponent = ({ ...props }) => {
  const cart = useAppSelector((state) => state.cart.items);

  return cart.length ? (
    <div
      css={css`
        height: 100vh;
      `}
    >
      <RemoveItemModal />
      <Heading title="Carrinho" hideIcon />

      <Scrollbars
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 15px 20px;
            padding-bottom: 80px;
          `}
        >
          <Review items={cart} />
        </div>
      </Scrollbars>
    </div>
  ) : (
    <EmptyCart />
  );
};

const EmptyCart: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigation = useAppSelector(
    (state) => state.app.navigation,
    () => true
  );
  return (
    <div
      css={css`
        height: calc(100vh - 74px);
      `}
    >
      <Heading title="Carrinho" hideIcon />

      <Scrollbars
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 20px;
            padding-bottom: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
          `}
        >
          <svg
            width="289"
            height="296"
            viewBox="0 0 289 296"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
              transform: translateX(-10px);
              width: 160px;
              height: auto;
            `}
          >
            <path
              d="M164.23 113.756C180.477 113.756 196.475 120.838 210.14 133.884C213.752 137.033 213.997 142.572 210.696 146.024C207.38 149.498 201.837 149.498 198.539 146.043C187.387 135.372 175.671 130.543 164.23 130.543C152.803 130.543 141.11 135.372 129.957 146.043C128.357 147.626 126.159 148.492 123.899 148.433C116.374 148.24 112.858 139.016 118.379 133.881C132.021 120.834 147.998 113.756 164.23 113.756ZM199.232 80.6303C205.949 80.6303 211.411 86.0952 211.411 92.8107C211.411 99.5299 205.946 104.973 199.232 104.973C192.518 104.973 187.075 99.5299 187.075 92.8107C187.075 86.0952 192.518 80.6303 199.232 80.6303ZM129.264 80.6303C135.982 80.6303 141.421 86.0952 141.421 92.8107C141.421 99.5299 135.978 104.973 129.264 104.973C122.528 104.973 117.086 99.5299 117.086 92.8107C117.086 86.0952 122.525 80.6303 129.264 80.6303ZM16.6618 1.01367C0.277758 1.47617 -2.32695 26.1626 13.5347 30.4694L34.2241 37.7658L58.5594 164.517C61.4679 179.687 66.2957 193.41 73.0242 205.22C82.9243 222.614 100.12 235.449 121.328 235.449H226.657C245.661 235.793 245.661 205.413 226.657 205.761H121.332C106.337 203.851 100.12 196.307 94.6214 185.244H233.645C246.402 185.244 253.523 173.933 256.665 160.85L287.081 57.509C288.703 40.5223 279.693 37.1664 263.924 37.1664L64.1949 37.3181L58.8336 20.9308C57.3849 16.0505 53.6798 12.2839 49.0298 10.9741L16.6618 1.01367Z"
              fill="#E3E3E3"
            />
            <path
              d="M218.325 250.579C205.894 250.579 195.805 260.676 195.805 273.105C195.805 285.551 205.894 295.645 218.325 295.645C230.77 295.645 240.859 285.551 240.859 273.105C240.859 260.676 230.77 250.579 218.325 250.579ZM128.512 250.579C116.067 250.579 105.97 260.676 105.97 273.105C105.97 285.551 116.067 295.645 128.512 295.645C140.958 295.645 151.032 285.551 151.032 273.105C151.032 260.676 140.958 250.579 128.512 250.579Z"
              fill="#E3E3E3"
            />
          </svg>
          <div
            css={css`
              color: var(--color-black);
              font-weight: bold;
              font-size: 16px;
              margin-top: 20px;
            `}
          >
            Seu carrinho está vazio
          </div>
          <div
            onClick={() => {
              const isCategoryOpen = navigation.find((n) => n.component === "Category");
              if (!isCategoryOpen) {
                dispatch(
                  openOverlay({
                    url: "/category/1",
                    component: "Category",
                  })
                );
              }
              setTimeout(
                () =>
                  dispatch(
                    closeOverlay({
                      url: `/cart`,
                      component: "Cart",
                    })
                  ),
                !isCategoryOpen ? 500 : 0
              );
            }}
            css={css`
              background-color: var(--color-primary);
              height: 40px;
              padding: 0 20px;
              border-radius: 100px;
              color: #fff;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              user-select: none;
              border: 1px solid var(--color-primary);
              margin: 0 5px;
              margin-top: 25px;
            `}
          >
            Ver produtos
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

type ReviewProps = {
  items: Array<CartItem>;
  disabled?: boolean;
  title?: string;
};

export const Review: FunctionComponent<ReviewProps> = ({ items, disabled, title }) => {
  const dispatch = useAppDispatch();

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Title>{title ? title : "Revise seu pedido"}</Title>
      <div
        css={css`
          // content wrapper
          background-color: #fff;
          border: 1px solid var(--color-line);
          border-radius: 8px;
        `}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              css={css`
                border-bottom: 1px solid var(--color-divider);
                padding: 14px 16px;
                display: flex;
              `}
              onClick={(e: SyntheticEvent) => {
                const target = e.target as Element;
                const isQtyEdit = target instanceof SVGElement || (" " + target.className + " ").replace(/[\n\t]/g, " ").indexOf(" qtyEdit ") > -1;
                if (!isQtyEdit && !disabled) {
                  // Set active product
                  dispatch(setActiveProduct(item));
                  // Open product overlay
                  dispatch(
                    openOverlay({
                      component: "Product",
                      url: `/cart/edit/${item.id}`,
                    })
                  );
                }
              }}
            >
              <Img alt={item?.name} src={item?.thumbnail ? item?.thumbnail : undefined} width={67} height={67} />
              <div
                css={css`
                  // Info wrapper
                  display: inline-flex;
                  flex-direction: column;
                  flex: 1;
                  padding-left: 17px;
                `}
              >
                <div
                  css={css`
                    // Product name
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 17px;
                    color: var(--color-black);
                    overflow: hidden;
                    max-height: 17px;
                    margin-top: 3px;
                    margin-bottom: 2px;
                  `}
                >
                  {item.name}
                </div>
                <div
                  css={css`
                    margin-bottom: 4px;
                  `}
                >
                  <SelectedAttributes attributes={item.attributes} />
                  <RemovedIngredients removeIngredients={item.removeIngredients} />
                </div>
                <div
                  css={css`
                    // Bottom wrapper
                    display: flex;
                    width: 100%;
                    margin-top: auto;
                    margin-bottom: 3px;
                    justify-content: space-between;
                    align-items: center;
                  `}
                >
                  <div
                    css={css`
                      // Price
                      font-weight: bold;
                      font-size: 14px;
                      line-height: 15px;
                      color: var(--color-black);
                    `}
                  >
                    {formatCurrency(calculatePrice(item, true))}
                  </div>
                  <div
                    css={css`
                      // Qty wrapper
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <div
                      css={css`
                        padding: 8px;
                        border-radius: 25px;
                        width: 25px;
                        height: 25px;
                        background-color: #fff;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;

                        ${disabled &&
                        css`
                          pointer-events: none;
                          opacity: 0.3;
                        `}
                      `}
                      className="qtyEdit"
                      onClick={() => {
                        if (!disabled) {
                          dispatch(
                            item.qty > 1
                              ? updateItem({
                                  ...item,
                                  qty: item.qty - 1,
                                })
                              : setItemToRemove(item)
                          );
                        }
                      }}
                    >
                      <svg width="13" height="3" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0915527 1.6001H12.0916" stroke="black" strokeWidth="1.86813" />
                      </svg>
                    </div>
                    <div
                      css={css`
                        width: 35px;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 21px;
                        color: #000000;
                        text-align: center;
                        user-select: none;
                      `}
                      className="qtyEdit"
                    >
                      {item.qty}
                    </div>
                    <div
                      css={css`
                        padding: 8px;
                        border-radius: 25px;
                        width: 25px;
                        height: 25px;
                        background-color: #fff;
                        border: 1px solid #eee;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;

                        ${disabled &&
                        css`
                          pointer-events: none;
                          opacity: 0.3;
                        `}
                      `}
                      className="qtyEdit"
                      onClick={() => {
                        if (!disabled) {
                          dispatch(
                            updateItem({
                              ...item,
                              qty: item.qty < 99 ? item.qty + 1 : 99,
                            })
                          );
                        }
                      }}
                    >
                      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.919434 6.99976H14.1194" stroke="black" strokeWidth="1.86813" />
                        <path d="M7.51978 13.5994V0.399414" stroke="black" strokeWidth="1.86813" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <Totals items={items} />
      </div>
    </div>
  );
};

type TotalsProps = {
  items: Array<CartItem>;
};

const Totals: FunctionComponent<TotalsProps> = ({ items }) => {
  const subtotals = items.reduce((acc, item) => {
    // Todo calculate price with extra options
    return acc + calculatePrice(item);
  }, 0);
  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 14px;
        color: var(--color-black);
        padding: 10px 16px;
      `}
    >
      <div>Total:</div>
      <div
        css={css`
          // Totals price
          font-weight: bold;
          margin-left: 8px;
        `}
      >
        {formatCurrency(subtotals)}
      </div>
    </div>
  );
};

const RemoveItemModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const itemToRemove = useAppSelector((store) => store.cart.itemToRemove);

  const closeModal = () => {
    dispatch(setItemToRemove(undefined));
  };

  return (
    <Modal isOpen={!!itemToRemove} contentLabel="Confirmação para remover item do carrinho" onRequestClose={closeModal} shouldCloseOnOverlayClick={false}>
      <Title>Remover item</Title>
      <div>{itemToRemove && `Deseja remover ${itemToRemove.name} do carrinho?`}</div>
      <div
        css={css`
          // Buttons wrapper
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 25px;
        `}
      >
        <div
          onClick={() => {
            closeModal();
          }}
          css={css`
            background-color: var(--color-primary);
            height: 40px;
            padding: 0 20px;
            border-radius: 100px;
            color: var(--color-primary);
            background-color: #fff;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            border: 1px solid var(--color-line);
            margin: 0 5px;
          `}
        >
          Voltar
        </div>
        <div
          onClick={() => {
            // Remove item
            if (itemToRemove) dispatch(removeItem(itemToRemove));
          }}
          css={css`
            background-color: var(--color-primary);
            /* box-shadow: 0px 4px 10px rgba(34, 92, 134, 0.51); */
            height: 40px;
            padding: 0 20px;
            border-radius: 100px;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            border: 1px solid var(--color-primary);
            margin: 0 5px;
          `}
        >
          Remover
        </div>
      </div>
    </Modal>
  );
};

type SelectedAttributesProps = {
  attributes: Array<Attribute> | undefined;
};

const SelectedAttributes: FunctionComponent<SelectedAttributesProps> = ({ attributes }) => {
  var selectedOptions: Array<string> = [];
  attributes?.forEach((attr) => {
    attr.options.forEach((opt) => {
      if (opt.qty > 0) {
        // selectedOptions.push(`${opt.name} +${formatCurrency(price)}`);
        selectedOptions.push(`- ${opt.name}`);
      }
    });
  });

  return (
    <div
      css={css`
        // Product extras
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
        color: var(--color-black);
        display: flex;
        flex-direction: column;
        color: #a5a5a5;
      `}
    >
      {selectedOptions.map((opt, index) => (
        <span key={index}>{opt}</span>
      ))}
    </div>
  );
};

type RemovedIngredientsProps = {
  removeIngredients: Ingredient[];
};

const RemovedIngredients: FC<RemovedIngredientsProps> = ({ removeIngredients }) => {
  var selectedIngredients: Array<string> = [];
  removeIngredients?.forEach((ingredient) => {
    selectedIngredients.push(`${ingredient.name}`);
  });

  return (
    <Fragment>
      <div
        css={css`
          // Product extras
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: var(--color-black);
          display: flex;
          flex-direction: column;
          color: #a5a5a5;
        `}
      >
        {selectedIngredients.map((name, index) => (
          <span key={index}>- Retirar {name}</span>
        ))}
      </div>
    </Fragment>
  );
};

export default Cart;
