import { css } from "@emotion/react";
import { FC } from "react";

type InfoProps = {
  fullWidth?: boolean;
  flex?: number;
  last?: boolean;
  alignCenter?: boolean;
};

const Info: FC<InfoProps> = ({ children, fullWidth, flex, last, alignCenter, ...props }) => {
  return (
    <div
      css={css`
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 16px;
        transition: all 0.3 ease-in-out;
        /* position: relative; */

        ${!last &&
        css`
          padding-right: 16px;
        `}

        ${flex &&
        css`
          flex: ${flex};
        `}

        ${alignCenter &&
        css`
          align-items: center;
        `}
        

        ${fullWidth &&
        css`
          width: 100%;
          padding-right: 0;
        `}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default Info;
