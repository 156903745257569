import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "./app";
import { Catalog, Ingredient } from "./catalog";

interface CartState {
  items: Array<CartItem>;
  itemToRemove: CartItem | undefined;
  orderConfirmation: Order | undefined;
  isLoading: boolean;
  sidesToAdd: CartItem[];
}

const initialState: CartState = {
  items: [],
  itemToRemove: undefined,
  orderConfirmation: undefined,
  isLoading: false,
  sidesToAdd: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      state.items.push(action.payload);
    },
    updateItem: (state, action: PayloadAction<CartItem>) => {
      const index = state.items.findIndex((el) => el.itemId === action.payload.itemId);
      if (index > -1) state.items[index] = action.payload;
    },
    setItemToRemove: (state, action: PayloadAction<CartItem | undefined>) => {
      state.itemToRemove = action.payload;
    },
    removeItem: (state, action: PayloadAction<CartItem>) => {
      state.items = state.items.filter((item) => item.itemId !== action.payload.itemId);
      state.itemToRemove = undefined;
    },
    setOrderConfirmation: (state, action: PayloadAction<Order | undefined>) => {
      state.orderConfirmation = action.payload;
    },
    removeAllItems: (state) => {
      state.items = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addSide: (state, action: PayloadAction<CartItem>) => {
      state.sidesToAdd.push(action.payload);
    },
    updateSide: (state, action: PayloadAction<CartItem>) => {
      const index = state.sidesToAdd.findIndex((el) => el.itemId === action.payload.itemId);
      if (index > -1) state.sidesToAdd[index] = action.payload;
    },
    removeSide: (state, action: PayloadAction<CartItem>) => {
      state.sidesToAdd = state.sidesToAdd.filter((item) => item.itemId !== action.payload.itemId);
    },
    resetSides: (state) => {
      state.sidesToAdd = [];
    },
    addSidesToCart: (state) => {
      state.items = [...state.items, ...state.sidesToAdd];
      state.sidesToAdd = [];
    },
  },
});

// Definitions
export type Order = {
  id: number | undefined;
  store: Store | undefined;
  createdAt: number;
  items: Array<CartItem>;
};

export interface CartItem extends Catalog {
  itemId: number | undefined;
  qty: number;
  removeIngredients: Ingredient[];
}

export const { addToCart, updateItem, setItemToRemove, removeItem, removeAllItems, setOrderConfirmation, setLoading, addSide, updateSide, removeSide, resetSides, addSidesToCart } = cartSlice.actions;

export default cartSlice.reducer;
