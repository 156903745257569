import { FunctionComponent, memo, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setSearchQuery } from "../store/slices/catalog";

// Components
import Heading from "./Heading";
import { getSortBy, VirtualList } from "./Category";
import Scrollbars from "react-custom-scrollbars";

const Search: FunctionComponent = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const searchInput = useRef<HTMLInputElement | null>(null);
  const searchQuery = useAppSelector((state) => state.catalog.searchQuery);

  const [timer, setTimer] = useState<any>();

  return (
    <div
      css={css`
        height: calc(100vh - 6px);
      `}
    >
      <Heading title={"Busca"} hideIcon>
        <div
          css={css`
            // Input wrapper
            width: 100%;
            position: relative;
            margin-bottom: 12px;
            margin-top: -4px;
          `}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
              position: absolute;
              top: 11px;
              left: 13px;
              width: 19px;
              height: auto;
            `}
          >
            <path
              d="M9.89583 3.125C11.6916 3.125 13.4138 3.83835 14.6835 5.10813C15.9533 6.37791 16.6667 8.1001 16.6667 9.89583C16.6667 11.5729 16.0521 13.1146 15.0417 14.3021L15.3229 14.5833H16.1458L21.3542 19.7917L19.7917 21.3542L14.5833 16.1458V15.3229L14.3021 15.0417C13.1146 16.0521 11.5729 16.6667 9.89583 16.6667C8.1001 16.6667 6.37791 15.9533 5.10813 14.6835C3.83835 13.4138 3.125 11.6916 3.125 9.89583C3.125 8.1001 3.83835 6.37791 5.10813 5.10813C6.37791 3.83835 8.1001 3.125 9.89583 3.125ZM9.89583 5.20833C7.29167 5.20833 5.20833 7.29167 5.20833 9.89583C5.20833 12.5 7.29167 14.5833 9.89583 14.5833C12.5 14.5833 14.5833 12.5 14.5833 9.89583C14.5833 7.29167 12.5 5.20833 9.89583 5.20833Z"
              fill="#666666"
            />
          </svg>
          <input
            ref={searchInput}
            css={css`
              width: 100%;
              border: 1px solid var(--color-line);
              border-radius: 8px;
              padding: 12px 14px;
              padding-left: 36px;
              font-size: 13px;
              box-shadow: 0px 1px 6px rgba(217, 217, 217, 0.51);

              &:focus {
                outline: 0;
              }
            `}
            onKeyUp={() => {
              clearTimeout(timer);
              setTimer(
                setTimeout(() => {
                  const query = searchInput.current!.value;
                  dispatch(setSearchQuery(query));
                }, 333)
              );
            }}
            placeholder={`O que deseja buscar?`}
          />
        </div>
      </Heading>

      {/* Content */}
      <SearchResults query={searchQuery} />
    </div>
  );
};

const SearchResults = memo(({ query }: { query: string }) => {
  const collection = useAppSelector((state) => state.catalog.collection);
  const sortBy = useAppSelector((state) => state.catalog.sortBy);
  const filteredCollection = collection
    .filter((el) => {
      return el.name.includes(query);
    })
    .sort(getSortBy(sortBy));

  return (
    <div css={css``}>
      {filteredCollection.length ? (
        <VirtualList items={filteredCollection} />
      ) : (
        <EmptyResults />
      )}
    </div>
  );
});

const EmptyResults = () => {
  return (
    <div
      css={css`
        height: calc(100vh - 129px);
      `}
    >
      <Scrollbars
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 20px;
            padding-bottom: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
          `}
        >
          <svg
            width="72"
            height="96"
            viewBox="0 0 72 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
              transform: translateX(-7px);
              width: 110px;
              height: auto;
            `}
          >
            <path
              d="M68.3 80.3L52.5 67.3C55.1 63 56.7 58 56.7 52.7C56.7 37.2 44.1 24.6 28.6 24.6C13.1 24.6 0.5 37.2 0.5 52.6C0.5 68 13.1 80.7 28.6 80.7C34 80.7 39 79.2 43.2 76.5L56.2 92.3C59.4 96.2 65.3 96.5 68.9 92.9C72.5 89.4 72.3 83.5 68.3 80.3ZM8.2 52.6C8.2 41.4 17.3 32.2 28.6 32.2C39.9 32.2 49 41.4 49 52.6C49 63.8 39.8 73 28.6 73C17.4 73 8.2 63.9 8.2 52.6Z"
              fill="#e3e3e3"
            />
            <path
              d="M28.6 17.3C30.7 17.3 32.4001 15.6 32.4001 13.5V4.3C32.4001 2.2 30.7 0.5 28.6 0.5C26.5 0.5 24.8 2.2 24.8 4.3V13.4C24.8 15.6 26.5 17.3 28.6 17.3Z"
              fill="#e3e3e3"
            />
            <path
              d="M46.2001 22C46.8001 22.3 47.5001 22.5 48.1001 22.5C49.4001 22.5 50.7001 21.8 51.4001 20.6L56.0001 12.7C57.1001 10.9 56.4001 8.50004 54.6001 7.50004C52.8001 6.40004 50.4001 7.10004 49.4001 8.90004L44.8001 16.8C43.8001 18.6 44.4001 21 46.2001 22Z"
              fill="#e3e3e3"
            />
            <path
              d="M5.79999 20.6C6.49999 21.8 7.79999 22.5 9.09999 22.5C9.69999 22.5 10.4 22.3 11 22C12.8 20.9 13.5 18.6 12.4 16.8L7.79999 8.90004C6.69999 7.10004 4.39999 6.40004 2.59999 7.50004C0.799991 8.60004 0.0999901 10.9 1.19999 12.7L5.79999 20.6Z"
              fill="#e3e3e3"
            />
            <path
              d="M38 43.2C36.5 41.7 34.0999 41.7 32.5999 43.2L28.5999 47.2L24.5999 43.2C23.0999 41.7 20.7 41.7 19.2 43.2C17.7 44.7 17.7 47.0999 19.2 48.5999L23.2 52.5999L19.2 56.5999C17.7 58.0999 17.7 60.5 19.2 62C19.9 62.7 20.9 63.0999 21.9 63.0999C22.9 63.0999 23.8999 62.7 24.5999 62L28.5999 58L32.5999 62C33.2999 62.7 34.2999 63.0999 35.2999 63.0999C36.2999 63.0999 37.3 62.7 38 62C39.5 60.5 39.5 58.0999 38 56.5999L34 52.5999L38 48.5999C39.5 47.0999 39.5 44.7 38 43.2Z"
              fill="#e3e3e3"
            />
          </svg>

          <div
            css={css`
              color: var(--color-black);
              font-weight: bold;
              font-size: 16px;
              margin-top: 30px;
            `}
          >
            Nenhum item encontrado
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Search;
