import { css } from "@emotion/react";
import { Fragment, FunctionComponent } from "react";
import Categories from "./Home/Categories";
import Header from "./Home/Header";
import StoreInfo from "./Home/StoreInfo";
import { useAppDispatch, useAppSelector } from "./hooks";
import { openOverlay } from "./store/slices/app";

const Home: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.app.isLoading);

  return (
    <Fragment>
      <Header />
      <div
        css={css`
          display: block;
          background-color: #eeeeee;
          height: 100%;
          border-radius: var(--border-radius-overlay)
            var(--border-radius-overlay) 0 0;
        `}
      >
        <StoreInfo isLoading={isLoading} />
        <div
          css={css`
            // Heading
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            padding: 0 20px;
          `}
        >
          <h1
            css={css`
              font-size: 17.5px;
              font-weight: 900;
              letter-spacing: -0.5px;
              line-height: 22px;
              padding-left: 8px;
              width: 50%;
            `}
          >
            O que gostaria de pedir hoje?
          </h1>

          <div
            css={css`
              // Search icon
            `}
            onClick={() => {
              !isLoading &&
                dispatch(
                  openOverlay({
                    url: `/search`,
                    component: "Search",
                  })
                );
            }}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.89583 3.125C11.6916 3.125 13.4138 3.83835 14.6835 5.10813C15.9533 6.37791 16.6667 8.1001 16.6667 9.89583C16.6667 11.5729 16.0521 13.1146 15.0417 14.3021L15.3229 14.5833H16.1458L21.3542 19.7917L19.7917 21.3542L14.5833 16.1458V15.3229L14.3021 15.0417C13.1146 16.0521 11.5729 16.6667 9.89583 16.6667C8.1001 16.6667 6.37791 15.9533 5.10813 14.6835C3.83835 13.4138 3.125 11.6916 3.125 9.89583C3.125 8.1001 3.83835 6.37791 5.10813 5.10813C6.37791 3.83835 8.1001 3.125 9.89583 3.125ZM9.89583 5.20833C7.29167 5.20833 5.20833 7.29167 5.20833 9.89583C5.20833 12.5 7.29167 14.5833 9.89583 14.5833C12.5 14.5833 14.5833 12.5 14.5833 9.89583C14.5833 7.29167 12.5 5.20833 9.89583 5.20833Z"
                fill="#225C86"
              />
            </svg>
          </div>
        </div>
        <Categories isLoading={isLoading} />
      </div>
    </Fragment>
  );
};

export default Home;
