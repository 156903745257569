import { css } from "@emotion/react";
import { format } from "date-fns";
import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Order, setOrderConfirmation } from "../store/slices/cart";

const OrderStatus: FunctionComponent = () => {
  const orderConfirmation = useAppSelector((store) => store.cart.orderConfirmation);
  return !orderConfirmation ? (
    <LoadingOrderStatus />
  ) : (
    <Wrapper>
      <StatusIcon>
        <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.5 0C10.9678 0 0 10.9678 0 24.5C0 38.0322 10.9678 49 24.5 49C38.0322 49 49 38.0322 49 24.5C49 10.9678 38.0322 0 24.5 0ZM21.9493 35.3889C21.2578 35.3889 20.5664 35.1276 20.0356 34.6049L14.4223 29.1577C13.3498 28.1015 13.3389 26.3783 14.3978 25.3112C15.4541 24.2359 17.1772 24.2305 18.2443 25.2867L21.9302 28.8229L33.4561 17.1364C34.5232 16.0747 36.2437 16.0666 37.3081 17.1228C38.3752 18.1872 38.3806 19.9076 37.3217 20.9747L23.8821 34.5858C23.3458 35.1194 22.6462 35.3889 21.9493 35.3889Z"
            fill="#00E476"
          />
        </svg>
      </StatusIcon>
      <StatusLabel>Pedido confirmado</StatusLabel>
      {orderConfirmation && <StatusUpdate ts={orderConfirmation.createdAt} />}
    </Wrapper>
  );
};

const Wrapper: FunctionComponent = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        padding: 15px 0 30px 0;
        min-height: 154px;
      `}
    >
      {children}
    </div>
  );
};
const StatusLabel: FunctionComponent = ({ children }) => {
  return (
    <h1
      css={css`
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 6px;
        color: var(--color-black);
      `}
    >
      {children}
    </h1>
  );
};

const StatusIcon: FunctionComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const orderConfirmation = useAppSelector((store) => store.cart.orderConfirmation);
  const items = useAppSelector((store) => store.cart.items);

  const store = useAppSelector((store) => store.app.store);

  const order: Order = {
    id: 1000490,
    store,
    createdAt: new Date().getTime(),
    items,
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        height: 60px;
        width: 60px;

        svg {
          width: 100%;
        }
      `}
      onClick={() => dispatch(setOrderConfirmation(orderConfirmation ? undefined : order))}
    >
      {children}
    </div>
  );
};

const LoadingOrderStatus: FunctionComponent = () => {
  const submittedAt = useAppSelector((store) => store.orderConfirmation.submittedAt);
  return (
    <Wrapper>
      <StatusIcon>
        <LoadingSpinner />
      </StatusIcon>
      <StatusLabel>Confirmando seu pedido</StatusLabel>
      {submittedAt && <StatusUpdate ts={submittedAt} />}
    </Wrapper>
  );
};

type StatusUpdateProps = {
  ts: number;
};

const StatusUpdate: FunctionComponent<StatusUpdateProps> = ({ ts }) => {
  return (
    <div
      css={css`
        font-weight: normal;
        font-size: 13px;
        color: var(--color-black);
      `}
    >
      {format(ts, "dd/MM/yyyy kk:mm")}
    </div>
  );
};

const LoadingSpinner: FunctionComponent = () => {
  return (
    <svg
      width="120"
      height="30"
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        fill: var(--color-primary);
      `}
    >
      <circle cx="15" cy="15" r="15">
        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx="60" cy="15" r="9" fillOpacity="0.3">
        <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
    </svg>
  );
};

export default OrderStatus;
