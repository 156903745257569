import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrderConfirmationState {
  submittedAt: number | undefined;
  isLoading: boolean;
}

const initialState: OrderConfirmationState = {
  submittedAt: undefined,
  isLoading: false,
};

export const orderConfirmationSlice = createSlice({
  name: "orderConfirmation",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmittedAt: (state, action: PayloadAction<number | undefined>) => {
      state.submittedAt = action.payload;
    },
  },
});

export const { setLoading, setSubmittedAt } = orderConfirmationSlice.actions;

export default orderConfirmationSlice.reducer;
