import { css, keyframes } from "@emotion/react";
import { FunctionComponent } from "react";

type PlaceholderProps = {
  width: number | string;
  height: number | string;
  borderRadius?: number;
  backgroundColor?: string;
  margin?: number | string;
  marginBottom?: number | string;
};

export const getValue = (v: number | string) => {
  return typeof v === "string" ? v : `${v}px`;
};

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  width,
  height,
  borderRadius,
  backgroundColor,
  margin,
  marginBottom,
}) => {
  return (
    <div
      css={css`
        position: relative;
        float: left;
        z-index: 44;
        overflow: hidden;
        width: ${getValue(width)};
        height: ${getValue(height)};
        border-radius: ${getValue(borderRadius ? borderRadius : 8)};
        background-color: ${backgroundColor
          ? backgroundColor
          : "var(--color-placeholder)"};

        ${marginBottom &&
        css`
          margin-bottom: ${getValue(marginBottom)};
        `}
        ${margin &&
        css`
          margin: ${getValue(margin)};
        `}
      `}
    >
      <Activity />
    </div>
  );
};

const loading = keyframes`
    0%{
        left: -45%;
    }
    100%{
        left: 100%;
    }
`;

const Activity: FunctionComponent = () => {
  return (
    <div
      className="activity"
      css={css`
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        background-image: linear-gradient(
          to left,
          rgba(251, 251, 251, 0.05),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.6),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.05)
        );
        background-image: -moz-linear-gradient(
          to left,
          rgba(251, 251, 251, 0.05),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.6),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.05)
        );
        background-image: -webkit-linear-gradient(
          to left,
          rgba(251, 251, 251, 0.05),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.6),
          rgba(251, 251, 251, 0.3),
          rgba(251, 251, 251, 0.05)
        );
        animation: ${loading} 2.2s infinite;
        z-index: 45;
        opacity: 0.4;
        /* filter: contrast(40%); */
      `}
    ></div>
  );
};

export default Placeholder;
