import { FunctionComponent } from "react";
import { css } from "@emotion/react";
import Logo from "../App/Logo";

const Header: FunctionComponent = ({ children, ...props }) => {
  return (
    <div
      css={css`
        background-color: var(--color-primary);
        border-bottom: 1px solid var(--line);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
        padding-bottom: 26px;
        margin-bottom: -26px;
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 44px;
          align-items: center;
          justify-content: space-between;
          color: var(--color-dark-gray);
        `}
      >
        {/* <Menu /> */}
        <div
          css={css`
            flex: 1;
            /* padding-right: 50px; */
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          `}
        >
          <Logo />
        </div>
      </div>
    </div>
  );
};

// const Menu: FunctionComponent = () => {
//   return (
//     <div
//       css={css`
//         padding: 0 15px;
//       `}
//     >
//       <svg
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z"
//           fill="white"
//         />
//       </svg>
//     </div>
//   );
// };

export default Header;
