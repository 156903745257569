import { css } from "@emotion/react";
import { FunctionComponent, ReactText, useRef } from "react";
import { Button } from "../FloatingButton";
import { useAppDispatch, useAppSelector } from "../hooks";
import { closeOverlay, openOverlay } from "../store/slices/app";
import { addSidesToCart, addToCart, updateItem } from "../store/slices/cart";
import { calculatePrice, formatCurrency } from "../store/slices/catalog";

// Toast
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const AddToCart: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const sidesToAdd = useAppSelector((store) => store.cart.sidesToAdd);
  const navigation = useAppSelector((store) => store.app.navigation);
  const overlay = navigation[navigation.length - 1];
  const isUpdate = overlay.url.startsWith("/cart/edit");
  const activeProduct = useAppSelector((store) => store.catalog.activeProduct);
  const price = activeProduct ? calculatePrice(activeProduct, false, sidesToAdd) : 0;
  const toastId = useRef<ReactText | null>(null);

  return (
    <Button>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
        `}
        onClick={() => {
          if (activeProduct) {
            var canAddProduct = true;

            // Check required attributes
            activeProduct.attributes?.forEach((attr) => {
              if (attr.required) {
                const selectedOpt = attr.options.find((opt) => opt.qty > 0);
                if (!selectedOpt) {
                  canAddProduct = false;
                  // Show toast error or update it
                  if (!toastId.current) {
                    toastId.current = toast.error("Precisa escolher as opções obrigatórias para adicionar ao carrinho.", {
                      transition: Slide,
                      onClose: () => (toastId.current = null),
                      style: {
                        minHeight: 75,
                        borderRadius: 0,
                      },
                    });
                  } else {
                    toast.update(toastId.current);
                  }
                  // Scroll to attribute
                  const attrEl = document.getElementById(`attr-${attr.id}`);
                  attrEl?.scrollIntoView({
                    block: "nearest",
                    behavior: "smooth",
                  });
                }
              }
            });

            if (canAddProduct) {
              if (toastId.current) toast.dismiss();

              if (isUpdate) {
                // Update product
                dispatch(updateItem(activeProduct));
              } else {
                // Add product
                const cartItem = {
                  ...activeProduct,
                  itemId: new Date().getTime(),
                };
                dispatch(addToCart(cartItem));

                // Add sides to cart
                dispatch(addSidesToCart());

                // Open cart overlay
                dispatch(
                  openOverlay({
                    url: "/cart",
                    component: "Cart",
                  })
                );
              }

              setTimeout(
                () =>
                  dispatch(
                    closeOverlay({
                      url: `${isUpdate ? "/cart/edit/" : "/product/"}${activeProduct.id}`,
                      component: "Product",
                    })
                  ),
                isUpdate ? 0 : 500
              );
            }
          }
        }}
      >
        <div
          css={css`
            flex: 1;
            text-align: center;
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              font-weight: bold;
              font-size: 15px;
              line-height: 18px;
              margin-right: 10px;
            `}
          >
            {isUpdate ? "Atualizar" : "Adicionar"}
          </div>
          <div
            css={css`
              font-weight: normal;
            `}
          >
            {activeProduct && formatCurrency(price)}
          </div>
        </div>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.5303 12.5303C20.8232 12.2374 20.8232 11.7626 20.5303 11.4697L15.7574 6.6967C15.4645 6.40381 14.9896 6.40381 14.6967 6.6967C14.4038 6.98959 14.4038 7.46447 14.6967 7.75736L18.9393 12L14.6967 16.2426C14.4038 16.5355 14.4038 17.0104 14.6967 17.3033C14.9896 17.5962 15.4645 17.5962 15.7574 17.3033L20.5303 12.5303ZM5 12.75L20 12.75L20 11.25L5 11.25L5 12.75Z"
            fill="white"
          />
        </svg>
      </div>
    </Button>
  );
};

export default AddToCart;
