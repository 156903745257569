import { FunctionComponent } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Scrollbars } from "react-custom-scrollbars";
import VMasker from "vanilla-masker";

// Components
import Heading from "./Heading";
import { setCustomer, setDeliveryInfo } from "../store/slices/app";
import Label from "../App/Label";
import Input from "../App/Input";
import Info from "../App/Info";
import Row from "../App/Row";
import { resetValidation } from "../store/slices/validation";

const InfoReview: FunctionComponent = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.app.customer);
  const validation = useAppSelector((store) => store.validation.data.infoReview);
  const deliveryInfo = useAppSelector((store) => store.app.deliveryInfo);

  return (
    <div
      css={css`
        height: 100vh;
      `}
    >
      <Heading title="Confirme seus dados" hideIcon />

      <Scrollbars
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 15px 20px;
            padding-bottom: 80px;
          `}
        >
          <Row>
            <Info fullWidth>
              <Label>Nome</Label>
              <Input
                value={customer.name}
                setValue={(value) => {
                  // const maskedValue = VMasker.toPattern(value, "99:99");
                  dispatch(setCustomer({ ...customer, name: value }));
                }}
                validation={validation.customerName}
                resetValidation={() => {
                  dispatch(resetValidation("infoReview.customerName"));
                }}
              />
            </Info>
          </Row>
          <Row>
            <Info fullWidth>
              <Label>Telefone</Label>
              <Input
                value={customer.phone}
                setValue={(value) => {
                  const maskedValue = VMasker.toPattern(value, "(99) 99999-9999");
                  dispatch(setCustomer({ ...customer, phone: maskedValue }));
                }}
                validation={validation.customerPhone}
                resetValidation={() => {
                  dispatch(resetValidation("infoReview.customerPhone"));
                }}
              />
            </Info>
          </Row>
          <Row>
            <Info fullWidth>
              <Label>Entrega</Label>
              <Input
                value={deliveryInfo}
                setValue={(value) => {
                  dispatch(setDeliveryInfo(value));
                }}
                validation={validation.deliveryInfo}
                resetValidation={() => {
                  dispatch(resetValidation("infoReview.deliveryInfo"));
                }}
              />
            </Info>
          </Row>
        </div>
      </Scrollbars>
    </div>
  );
};

export default InfoReview;
