import { infoReviewValidation, infoReviewRules } from "./infoReview";

const validation: any = {
  infoReview: infoReviewValidation,
};

export default validation;

export const rules: any = {
  infoReview: infoReviewRules,
};
