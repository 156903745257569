import { FunctionComponent } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Scrollbars } from "react-custom-scrollbars";

// Components
import { AttributeOption, setQty, setOption, formatCurrency, Side, Ingredient, setActiveProduct } from "../store/slices/catalog";
import Heading from "./Heading";
import Title from "../App/Title";
import Img from "../App/Img";
import { addSide, CartItem, removeSide, updateSide } from "../store/slices/cart";

const Product: FunctionComponent = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const activeProduct = useAppSelector((state) => state.catalog.activeProduct);
  const qty = activeProduct ? activeProduct.qty : 0;
  const navigation = useAppSelector((store) => store.app.navigation);
  const overlay = navigation[navigation.length - 1];
  const isUpdate = overlay.url.startsWith("/cart/edit");

  return typeof activeProduct != "undefined" ? (
    <div
      css={css`
        height: 100vh;
      `}
    >
      <Heading title={activeProduct.name} hideIcon />

      <Scrollbars
        css={css`
          width: 100%;
          height: calc(100vh - 63px) !important;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 15px 20px;
          `}
        >
          <div
            css={css`
              float: left;
              margin-bottom: 18px;
            `}
          >
            <Img alt={activeProduct?.name} src={activeProduct?.image ? activeProduct?.image : undefined} width="calc(100vw - 40px)" height="calc(100vw - 40px)" />
          </div>

          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;
              width: 100%;
            `}
          >
            <div
              css={css`
                // Product price
                font-weight: bold;
                font-size: 17.2941px;
                line-height: 21px;
              `}
            >
              {formatCurrency(activeProduct.price)}
            </div>
            <div
              css={css`
                // Qty wrapper
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  padding: 8px;
                  border-radius: 30px;
                  width: 30px;
                  height: 30px;
                  background-color: #fff;
                  border: 1px solid #eee;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  user-select: none;
                `}
                onClick={() => {
                  dispatch(setQty(qty > 1 ? qty - 1 : 1));
                }}
              >
                <svg width="13" height="3" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.0915527 1.6001H12.0916" stroke="black" strokeWidth="1.86813" />
                </svg>
              </div>
              <div
                css={css`
                  width: 35px;
                  font-weight: bold;
                  font-size: 17.1429px;
                  line-height: 21px;
                  color: #000000;
                  text-align: center;
                  user-select: none;
                `}
              >
                {qty}
              </div>
              <div
                css={css`
                  padding: 8px;
                  border-radius: 30px;
                  width: 30px;
                  height: 30px;
                  background-color: #fff;
                  border: 1px solid #eee;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  user-select: none;
                `}
                onClick={() => {
                  dispatch(setQty(qty < 99 ? qty + 1 : 99));
                }}
              >
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.919434 6.99976H14.1194" stroke="black" strokeWidth="1.86813" />
                  <path d="M7.51978 13.5994V0.399414" stroke="black" strokeWidth="1.86813" />
                </svg>
              </div>
            </div>
          </div>
          {activeProduct.description && (
            <div
              css={css`
                // Product description
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 22px;
              `}
            >
              {activeProduct.description}
            </div>
          )}
          {activeProduct.attributes &&
            activeProduct.attributes.map((attr, index) => (
              <div
                css={css`
                  // Product options group
                  margin-bottom: 22px;
                `}
                id={`attr-${attr.id}`}
                key={index}
              >
                <div
                  css={css`
                    // Title wrapper
                    position: relative;
                    margin-bottom: 14px;
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Title noMargin>{attr.title}</Title>
                  {attr.required && (
                    <div
                      css={css`
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: var(--color-red);
                        border-radius: 4px;
                        padding: 4px;
                        font-size: 9px;
                        font-weight: bold;
                        color: #fff;
                      `}
                    >
                      OBRIGATÓRIO
                    </div>
                  )}
                </div>
                {attr.options.map((option, index) => (
                  <ProductOption key={index} data={option} />
                ))}
              </div>
            ))}

          {activeProduct.ingredients?.length ? (
            <div
              css={css`
                margin-bottom: 22px;
              `}
            >
              <div
                css={css`
                  position: relative;
                  margin-bottom: 14px;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Title noMargin>Deseja retirar algo?</Title>
              </div>
              {activeProduct.ingredients.map((ingredient, index) => {
                return <ProductIngredient key={index} data={ingredient} />;
              })}
            </div>
          ) : (
            !1
          )}
          {!isUpdate && activeProduct.sides.length ? (
            <div
              css={css`
                margin-bottom: 22px;
              `}
            >
              <div
                css={css`
                  position: relative;
                  margin-bottom: 14px;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Title noMargin>Acompanhamentos:</Title>
              </div>
              {activeProduct.sides.map((side, index) => {
                return <ProductSide key={index} data={side} />;
              })}
            </div>
          ) : (
            !1
          )}

          <EmptySpace />
        </div>
      </Scrollbars>
    </div>
  ) : (
    <div>No match</div>
  );
};

type ProductOptionProps = {
  data: AttributeOption;
};

const ProductOption: FunctionComponent<ProductOptionProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  return (
    <div
      css={css`
        // Option
        background: #ffffff;
        border: 1px solid var(--color-line);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        min-height: 64px;
        user-select: none;
      `}
      onClick={(e) => {
        dispatch(setOption({ ...data, qty: data.qty ? 0 : 1 }));
      }}
    >
      <div
        css={css`
          // Option info wrapper
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
          `}
        >
          {data.name}
        </div>
        {data.price ? (
          <div
            css={css`
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: #000000;
            `}
          >
            +{formatCurrency(data.price)}
          </div>
        ) : null}
      </div>

      <OptionCheckbox selected={data.qty > 0} />
    </div>
  );
};

type ProductSideProps = {
  data: Side;
};

const ProductSide: FunctionComponent<ProductSideProps> = ({ data }) => {
  const { catalogId } = data;
  const collection = useAppSelector((store) => store.catalog.collection);
  const sidesToAdd = useAppSelector((store) => store.cart.sidesToAdd);
  const product = collection.find((el) => el.id === catalogId);
  var item;
  if (product) {
    const side = sidesToAdd.find((s) => s.id === catalogId);
    item = side ? side : { ...product, itemId: undefined, qty: 0, removeIngredients: [] };
  }

  return item ? (
    <div
      css={css`
        // Option
        background: #ffffff;
        border: 1px solid var(--color-line);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        min-height: 64px;
        user-select: none;
      `}
      onClick={(e) => {}}
    >
      <div
        css={css`
          // Option info wrapper
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
          `}
        >
          {product?.name}
        </div>
        {product?.price ? (
          <div
            css={css`
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: #000000;
            `}
          >
            {formatCurrency(product?.price)}
          </div>
        ) : null}
      </div>

      <ProductSideQty item={item} />
    </div>
  ) : null;
};

type ProductIngredientProps = {
  data: Ingredient;
};

const ProductIngredient: FunctionComponent<ProductIngredientProps> = ({ data }) => {
  const { id, name } = data;
  const dispatch = useAppDispatch();
  const activeProduct = useAppSelector((state) => state.catalog.activeProduct);
  const isSelected = !!activeProduct?.removeIngredients.find((el) => el.id === id);

  return (
    <div
      css={css`
        // Option
        background: #ffffff;
        border: 1px solid var(--color-line);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 16px 20px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        min-height: 64px;
        user-select: none;
      `}
      onClick={(e) => {
        if (activeProduct) {
          // Remove if exists in removeIngredients array
          if (isSelected) {
            const removeIngredients = [...activeProduct.removeIngredients].filter((el) => el.id !== data.id);
            dispatch(setActiveProduct({ ...activeProduct, removeIngredients }));
          } else {
            // Else add to removeIngredients array
            var removeIngredients = [...activeProduct.removeIngredients];
            removeIngredients.push(data);
            dispatch(setActiveProduct({ ...activeProduct, removeIngredients }));
          }
        }
      }}
    >
      <div
        css={css`
          // Option info wrapper
          display: flex;
          flex: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
          `}
        >
          {name}
        </div>
      </div>

      <OptionCheckbox selected={isSelected} />
    </div>
  );
};

type OptionCheckboxProps = {
  selected: boolean;
};

const OptionCheckbox: FunctionComponent<OptionCheckboxProps> = ({ selected }) => {
  return (
    <div
      css={css`
        // Checkbox
        position: relative;
        background: #fbfbfb;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        width: 19px;
        height: 19px;
        border-radius: 2px;

        ${selected &&
        css`
          &:after {
            content: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.175 12.95L0 7.775L2.35833 5.41667L5.175 8.24167L13.4083 0L15.7667 2.35833L5.175 12.95Z' fill='%23225C86'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 0;
            left: 0;
            width: 19px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      `}
    ></div>
  );
};

type ProductSideQtyProps = {
  item: CartItem;
};

const ProductSideQty: FunctionComponent<ProductSideQtyProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  return (
    <div
      css={css`
        // Qty wrapper
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          padding: 8px;
          border-radius: 30px;
          width: 30px;
          height: 30px;
          background-color: #fff;
          border: 1px solid #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
        `}
        onClick={() => {
          const _item = { ...item, qty: item.qty > 0 ? item.qty - 1 : 0 };
          if (item.qty > 0) {
            dispatch(updateSide(_item));
          } else {
            dispatch(removeSide(_item));
          }
        }}
      >
        <svg width="13" height="3" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0915527 1.6001H12.0916" stroke="black" strokeWidth="1.86813" />
        </svg>
      </div>
      <div
        css={css`
          width: 35px;
          font-weight: bold;
          font-size: 17.1429px;
          line-height: 21px;
          color: #000000;
          text-align: center;
          user-select: none;
        `}
      >
        {item.qty}
      </div>
      <div
        css={css`
          padding: 8px;
          border-radius: 30px;
          width: 30px;
          height: 30px;
          background-color: #fff;
          border: 1px solid #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
        `}
        onClick={() => {
          const _item = {
            ...item,
            qty: item.qty + 1,
          };
          if (_item.itemId) {
            dispatch(updateSide(_item));
          } else {
            dispatch(addSide({ ..._item, itemId: new Date().getTime() }));
          }
        }}
      >
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.919434 6.99976H14.1194" stroke="black" strokeWidth="1.86813" />
          <path d="M7.51978 13.5994V0.399414" stroke="black" strokeWidth="1.86813" />
        </svg>
      </div>
    </div>
  );
};

const EmptySpace: FunctionComponent = () => {
  return (
    <div
      css={css`
        float: left;
        width: 60px;
        height: 60px;
      `}
    ></div>
  );
};

export default Product;
