import { css } from "@emotion/react";
import { FunctionComponent } from "react";
import Img from "../App/Img";
import Placeholder from "../App/Placeholder";
import { useAppSelector } from "../hooks";

type StoreInfoProps = {
  isLoading: boolean;
};

const StoreInfo: FunctionComponent<StoreInfoProps> = ({ isLoading }) => {
  const store = useAppSelector((store) => store.app.store);
  const hasImage = store && store.image;

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <Wrapper>
      <div
        css={css`
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px solid var(--color-line);
        `}
      >
        <Img
          alt={store?.name}
          src={
            hasImage
              ? store?.image
              : "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWO4ev3mfwAIjwOFv1AqOQAAAABJRU5ErkJggg=="
          }
          width={67}
          height={67}
          maxHeight={67}
        />
      </div>
      <Info>
        <h1
          css={css`
            font-weight: bold;
            font-size: 14px;
            color: var(--color-black);
            margin-bottom: 2px;
          `}
        >
          {store?.name}
        </h1>
        {store?.description && (
          <div
            css={css`
              font-size: 12px;
              line-height: 13px;
              color: var(--color-black);
              margin-bottom: 3px;
            `}
          >
            {store?.description}
          </div>
        )}

        <div
          css={css`
            font-size: 10px;
            line-height: 10px;
            color: var(--color-black);
          `}
        >
          Aberto das {store?.openAt} ~ {store?.closeAt}
        </div>
      </Info>
    </Wrapper>
  );
};

const LoadingSkeleton: FunctionComponent = () => {
  return (
    <Wrapper>
      <Placeholder width={67} height={67} />
      <Info>
        <Placeholder
          width={117}
          height={14}
          marginBottom={7}
          borderRadius={3}
        />
        <Placeholder width={130} height={7} marginBottom={5} borderRadius={2} />
        <Placeholder width={110} height={7} marginBottom={7} borderRadius={2} />
        <Placeholder width={80} height={7} marginBottom={0} borderRadius={2} />
      </Info>
    </Wrapper>
  );
};

const Wrapper: FunctionComponent = ({ children }) => {
  return (
    <div
      css={css`
        padding: 22px 25px;
        margin-bottom: 18px;
        display: flex;
        border-bottom: 1px solid var(--color-line);
        min-height: 114px;
      `}
    >
      {children}
    </div>
  );
};

const Info: FunctionComponent = ({ children }) => {
  return (
    <div
      css={css`
        // Info wrapper
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
      `}
    >
      {children}
    </div>
  );
};

export default StoreInfo;
