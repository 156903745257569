import React, { FunctionComponent } from "react";
import { Global, css } from "@emotion/react";
import reset from "emotion-reset";
import fonts from "./fonts";
import vars from "./vars";

const GlobalStyle: FunctionComponent = ({ ...props }) => {
  return (
    <Global
      styles={css`
        ${fonts}
        ${reset}
        ${vars}
        * {
          /* user-select: none; */
        }
        html {
          box-sizing: border-box;
        }
        *,
        *:before,
        *:after {
          /* box-sizing: inherit; */
          box-sizing: border-box;
        }
        body {
          color: var(--color-black);
          overflow: hidden;

          &,
          input,
          textarea {
            font-family: "Fira Sans", sans-serif;
          }
        }
        svg {
          display: block;

          * {
            shape-rendering: geometricPrecision;
          }
        }

        #root {
          display: flex;
          flex-direction: column;
          height: 100vh;

          > .scrollbar-container {
            scroll-behavior: smooth;
          }

          .swiper-slide.auto-width {
            width: auto !important;
          }

          /* .swiper-slide.auto-height {
            height: auto !important;
          } */

          .swiper-container.full-height {
            height: 100% !important;
          }
        }
      `}
      {...props}
    />
  );
};

export default GlobalStyle;
