import { css } from "@emotion/react";
import { FunctionComponent } from "react";
import { shallowEqual } from "react-redux";
import AddToCart from "./FloatingButton/AddToCart";
import Checkout from "./FloatingButton/Checkout";
import OpenCartOverlay from "./FloatingButton/OpenCartOverlay";
import PlaceOrder from "./FloatingButton/PlaceOrder";
import { useAppSelector } from "./hooks";

const FloatingButton: FunctionComponent = () => {
  const floatingButton = useAppSelector((state) => state.app.floatingButton, shallowEqual);

  const renderComponent = (floatingButton: string) => {
    switch (floatingButton) {
      case "Home":
        return <OpenCartOverlay />;
      case "Category":
        return <OpenCartOverlay />;
      case "Product":
        return <AddToCart />;
      case "Cart":
        return <Checkout />;
      case "InfoReview":
        return <PlaceOrder />;
      case "OrderConfirmation":
        return null;
      default:
        return <OpenCartOverlay />;
    }
  };

  return (
    <div
      css={css`
        // Wrapper
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        /* padding: 16px; */
        z-index: 100;
        user-select: none;
        background-color: var(--color-bg);
        /* border-top: 1px solid var(--color-line); */
      `}
    >
      {renderComponent(floatingButton)}
    </div>
  );
};

export const Button: FunctionComponent = ({ children }) => {
  return (
    <div
      css={css`
        background-color: var(--color-primary);
        box-shadow: 0px 4px 10px rgba(34, 92, 134, 0.51);
        height: 62px;
        /* border-radius: 100px; */
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      `}
    >
      {children}
    </div>
  );
};

export default FloatingButton;
