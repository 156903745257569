import { Fragment, FunctionComponent } from "react";
import { css } from "@emotion/react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Scrollbars } from "react-custom-scrollbars";

// Components
import Heading from "./Heading";
import { Review } from "./Cart";
import OrderStatus from "../OrderConfirmation/OrderStatus";
import { setOrderConfirmation } from "../store/slices/cart";
import Title from "../App/Title";

const OrderConfirmation: FunctionComponent = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(
    (state) => state.cart.items,
    () => true
  );
  const orderConfirmation = useAppSelector((state) => state.cart.orderConfirmation);

  return (
    <div
      css={css`
        height: calc(100vh - 72px);
      `}
    >
      <Heading
        title={`Pedido ${orderConfirmation ? "#" + orderConfirmation.id : ""}`}
        hideIcon
        hideBackButton={orderConfirmation ? false : true}
        onBack={() => {
          setTimeout(() => {
            // Clear order confirmation
            dispatch(setOrderConfirmation(undefined));
          }, 500);
        }}
      />

      <Scrollbars
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        {/* Content */}
        <div
          css={css`
            padding: 15px 20px;
            padding-bottom: 80px;
          `}
        >
          <OrderStatus />
          <DeliveryInfo />
          <Review title="Itens do pedido" items={orderConfirmation ? orderConfirmation.items : cart} disabled />
        </div>
      </Scrollbars>
    </div>
  );
};

type DeliveryInfoProps = {};

const DeliveryInfo: FunctionComponent<DeliveryInfoProps> = () => {
  const deliveryInfo = useAppSelector((store) => store.app.deliveryInfo);
  const customer = useAppSelector((store) => store.app.customer);

  return (
    <Fragment>
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Title>Nome</Title>
        <div
          css={css`
            // content wrapper
            background-color: #fff;
            border: 1px solid var(--color-line);
            padding: 14px 18px;
            border-radius: 8px;
          `}
        >
          <div
            css={css`
              // Time estimated
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              color: #000000;
            `}
          >
            {customer.name}
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Title>Telefone</Title>
        <div
          css={css`
            // content wrapper
            background-color: #fff;
            border: 1px solid var(--color-line);
            padding: 14px 18px;
            border-radius: 8px;
          `}
        >
          <div
            css={css`
              // Time estimated
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              color: #000000;
            `}
          >
            {customer.phone}
          </div>
        </div>
      </div>

      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Title>Entrega</Title>
        <div
          css={css`
            // content wrapper
            background-color: #fff;
            border: 1px solid var(--color-line);
            padding: 14px 18px;
            border-radius: 8px;
          `}
        >
          <div
            css={css`
              // Time estimated
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              color: #000000;
            `}
          >
            {deliveryInfo}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderConfirmation;
