export const infoReviewValidation = {
  name: [],
  category: [],
};

export const infoReviewRules = [
  {
    input: "customerName",
    isRequired: true,
  },
  {
    input: "customerPhone",
    isRequired: true,
  },
  {
    input: "deliveryInfo",
    isRequired: true,
  },
];
