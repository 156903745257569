import React, { Fragment, useEffect } from "react";
import { css } from "@emotion/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GlobalStyle from "./App/GlobalStyle";
import { useAppDispatch, useAppSelector } from "./hooks";

// Components
import FloatingButton from "./FloatingButton";
import Home from "./Home";
import Category from "./Overlay/Category";
import Product from "./Overlay/Product";
import Cart from "./Overlay/Cart";
import Search from "./Overlay/Search";
import InfoReview from "./Overlay/InfoReview";

import { TransitionGroup, Transition } from "react-transition-group";
import { result, setDeliveryInfo, setFloatingButton, setLastUpdated, setLoading, setStore } from "./store/slices/app";
import Frame from "./Frame";
import OrderConfirmation from "./Overlay/OrderConfirmation";
import ToastContainer from "./App/ToastContainer";
import axios from "axios";
import api from "./store/api";
import { setCategories, setCollection } from "./store/slices/catalog";

export default function App({ ...props }) {
  const dispatch = useAppDispatch();
  const navigation = useAppSelector((state) => state.app.navigation);
  const isLoading = useAppSelector((state) => state.app.isLoading);
  const lastUpdated = useAppSelector((state) => state.app.lastUpdated);

  // Floating button controller
  useEffect(() => {
    const component = navigation.length ? navigation[navigation.length - 1].component : "Home";
    dispatch(setFloatingButton(component));
  }, [dispatch, navigation]);

  useEffect(() => {
    dispatch(setStore(result.store));
    dispatch(setDeliveryInfo(result.store.deliveryInfo));
    dispatch(setCategories(result.categories));
    dispatch(setCollection(result.collection));
    dispatch(setLastUpdated(new Date().getTime()));
    dispatch(setLoading(false));
  }, [dispatch]);

  // // Fetch data
  // useEffect(() => {
  //   if (!lastUpdated) {
  //     const deliveryInfo = new URLSearchParams(window.location.search).get("deliveryInfo");
  //     axios
  //       .post(`${api.baseUrl}${api.fetchData}`, {
  //         id: new URLSearchParams(window.location.search).get("id"),
  //         deliveryInfo,
  //       })
  //       .then((r) => {
  //         const delay = 0;
  //         setTimeout(() => {
  //           dispatch(setStore(r.data.store));
  //           dispatch(setDeliveryInfo(deliveryInfo ? deliveryInfo : ""));
  //           dispatch(setCategories(r.data.categories));
  //           dispatch(setCollection(r.data.collection));
  //           dispatch(setLastUpdated(new Date().getTime()));
  //           dispatch(setLoading(false));
  //         }, delay);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setTimeout(() => window.location.reload(), 2000);
  //       });
  //   }
  // }, [lastUpdated, isLoading, dispatch]);

  return (
    <Router>
      <GlobalStyle />
      <ToastContainer />
      <Switch>
        <Route path={["/category/:id", "/category/"]}>
          <Category />
        </Route>
        <Route path="*"></Route>
      </Switch>
      <Switch>
        <Route path={["/frame/:src", "/frame/"]}>
          <Frame />
        </Route>
        <Route path="*">
          {!isLoading && (
            <Fragment>
              <FloatingButton />
              <TransitionGroup component={null}>
                {navigation.map(({ component, url }, i) => (
                  <Transition
                    key={url}
                    timeout={{
                      appear: 0,
                      enter: 0,
                      exit: 200,
                    }}
                  >
                    {(state) => (
                      // state change: exited -> entering -> entered -> exiting -> exited
                      <Overlay index={i} state={state} component={component} />
                    )}
                  </Transition>
                ))}
              </TransitionGroup>
            </Fragment>
          )}
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

interface OverlayProps {
  state: string;
  index: number;
  component: string;
}

const Overlay = ({ state, index, component }: OverlayProps) => {
  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding-top: 8px;
        transition: transform 200ms ease-in-out;

        // state change: exited -> entering -> entered -> exiting -> exited
        ${state === "exited" &&
        css`
          transform: translate3d(0, 100%, 0);
        `}

        ${state === "entering" &&
        css`
          transform: translate3d(0, 100%, 0);
        `}

        ${state === "entered" &&
        css`
          transform: translate3d(0, 0, 0);
        `}

        ${state === "exiting" &&
        css`
          transform: translate3d(0, 100%, 0);
        `}
      `}
    >
      <div
        css={css`
          // Wrapper
          background: var(--color-bg);
          border-radius: var(--border-radius-overlay) var(--border-radius-overlay) 0 0;
          height: 100%;
        `}
      >
        {component === "Category" && <Category />}
        {component === "Product" && <Product />}
        {component === "Search" && <Search />}
        {component === "Cart" && <Cart />}
        {component === "InfoReview" && <InfoReview />}
        {component === "OrderConfirmation" && <OrderConfirmation />}
      </div>
    </div>
  );
};
