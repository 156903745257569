import { FunctionComponent, useState, useLayoutEffect, memo, forwardRef, useCallback, createRef } from "react";
import { css } from "@emotion/react";
import { Scrollbars } from "react-custom-scrollbars";
import { openOverlay } from "../store/slices/app";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Catalog, Category as CategoryProps, formatCurrency, setActiveCategory, setActiveProduct, setSortBy } from "../store/slices/catalog";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

// Toast
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// Components
import Heading from "./Heading";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Title from "../App/Title";
import Img from "../App/Img";
import { resetSides } from "../store/slices/cart";

// const PADDING_SIZE = 100;
const ITEM_SIZE = 114;
const TOOLBAR_SIZE = 50;

function isElementInViewport(el: any) {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

function getPxOutsideBounds(el: any) {
  const rect = el.getBoundingClientRect();
  const clientWidth = window.innerWidth || document.documentElement.clientWidth;
  if (rect.right <= clientWidth) {
    // Left
    return rect.left * -1 + 20;
  } else if (rect.left >= 0) {
    // Right
    return (rect.right - clientWidth + 20) * -1;
  }
}

const Category: FunctionComponent = ({ ...props }) => {
  const [headingSwiper, setHeadingSwiper] = useState<any>(null);

  const [contentSwiper, setContentSwiper] = useState<any>(null);
  // const collection = useAppSelector((state) => state.catalog.collection);
  const categories = useAppSelector((state) => state.catalog.categories);

  return (
    <div
      css={css`
        height: 100vh;
      `}
    >
      <Heading title={"Categorias"}>
        <CategoriesLinks categories={categories} setHeadingSwiper={setHeadingSwiper} headingSwiper={headingSwiper} contentSwiper={contentSwiper} />
      </Heading>

      {/* Content */}
      <CategoryContent categories={categories} headingSwiper={headingSwiper} contentSwiper={contentSwiper} setContentSwiper={setContentSwiper} />
    </div>
  );
};

interface CategoryContentProps {
  categories: any;
  headingSwiper: any;
  contentSwiper: any;
  setContentSwiper: any;
}

export const getSortBy = (selectedOpt: string) => {
  switch (selectedOpt) {
    case "NameAsc":
      return (a: Catalog, b: Catalog) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
    case "NameDesc":
      return (a: Catalog, b: Catalog) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      };
    case "PriceAsc":
      return (a: Catalog, b: Catalog) => {
        if (a.price < b.price) {
          return -1;
        }
        if (a.price > b.price) {
          return 1;
        }
        return 0;
      };
    case "PriceDesc":
      return (a: Catalog, b: Catalog) => {
        if (a.price > b.price) {
          return -1;
        }
        if (a.price < b.price) {
          return 1;
        }
        return 0;
      };
    default:
      return () => 1;
  }
};

const CategoryContent = ({ categories, setContentSwiper }: CategoryContentProps) => {
  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.catalog.collection);
  const sortBy = useAppSelector((state) => state.catalog.sortBy);
  const activeCategory = useAppSelector(
    (state) => state.catalog.activeCategory,
    () => true
  );

  return (
    <div>
      <Swiper
        slidesPerView={1}
        onSlideChange={(swiper) => {
          // Set active category
          dispatch(setActiveCategory(categories[swiper.realIndex].id));
        }}
        onSwiper={(swiper) => {
          // Set swiper instance
          setContentSwiper(swiper);

          // Slide to active category
          categories.findIndex((el: CategoryProps) => el.id === activeCategory);
          swiper.slideTo(activeCategory, 0);
        }}
      >
        {categories.map(({ name, id }: { name: string; id: number }, index: number) => {
          const items = collection.filter((item: any) => item.category === id).sort(getSortBy(sortBy));
          return (
            <SwiperSlide virtualIndex={index} key={index}>
              <VirtualList items={items} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

interface CategoriesLinksProps {
  categories: any;
  setHeadingSwiper: any;
  headingSwiper: any;
  contentSwiper: any;
}

const CategoriesLinks = memo(({ categories, setHeadingSwiper, headingSwiper, contentSwiper }: CategoriesLinksProps) => {
  return (
    <div
      css={css`
        // Heading categories swiper wrapper
        margin: 0 -20px;
        width: calc(100% + 40px);
      `}
    >
      <Swiper
        // virtual
        spaceBetween={7}
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
        slidesPerView={"auto"}
        freeMode={true}
        onSwiper={(swiper) => setHeadingSwiper(swiper)}
      >
        {categories.map(({ name, id }: { name: string; id: number }, index: number) => {
          return (
            <SwiperSlide key={id} className="auto-width" virtualIndex={index}>
              <CategoryLink headingSwiper={headingSwiper} contentSwiper={contentSwiper} id={id} name={name} index={index} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
});

const CategoryLink = ({ headingSwiper, contentSwiper, id, index, name }: { headingSwiper: any; contentSwiper: any; id: any; index: any; name: any }) => {
  const dispatch = useAppDispatch();
  const activeCategory = useAppSelector((state) => state.catalog.activeCategory);
  const isActive = activeCategory === id;

  useLayoutEffect(() => {
    // Set heading category link visible if outside bonds
    if (isActive && headingSwiper) {
      const slide = headingSwiper.slides[index];
      if (!isElementInViewport(slide)) {
        const pxOutsideBounds = getPxOutsideBounds(slide);
        const translateValue = headingSwiper.translate;
        const newTranslateValue = translateValue + pxOutsideBounds;
        headingSwiper.translateTo(newTranslateValue);
      }
    }

    // Set content slide if not match with current active
    if (contentSwiper) contentSwiper.slideTo(activeCategory, 0);
  }, [activeCategory, contentSwiper, headingSwiper, index, isActive]);

  return (
    <div
      css={css`
        /* width: calc(50% - 10px); */
        float: left;
        font-size: 14px;
        text-align: center;
        padding: 8px 17px;
        /* margin: 5px; */
        background-color: #ffffff;
        border: 1px solid var(--color-line);
        border-radius: 50px;
        margin-bottom: 18px;

        ${isActive &&
        css`
          transition: all 0.1s ease-in-out;
          background-color: var(--color-primary);
          border-color: var(--color-blue-line);
          box-shadow: 0px 4px 10px rgba(34, 92, 134, 0.51);
          color: #fff;
        `}
      `}
      onClick={(e) => {
        // headingSwiper.slideTo(index);
        // const slide = headingSwiper.slides[headingSwiper.activeIndex];

        const slide = headingSwiper.slides[index];

        if (!isElementInViewport(slide)) {
          const pxOutsideBounds = getPxOutsideBounds(slide);
          const translateValue = headingSwiper.translate;
          const newTranslateValue = translateValue + pxOutsideBounds;
          headingSwiper.translateTo(newTranslateValue);
        }

        // Set active category
        dispatch(setActiveCategory(id));

        // Slide content swiper
        if (contentSwiper) contentSwiper.slideTo(index, 0);

        // // Open category overlay
        // dispatch(
        //   openOverlay({
        //     component: "Category",
        //     url: `/category/${id}`,
        //   })
        // );
      }}
    >
      {name}
    </div>
  );
};

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }: any) => {
  const refSetter = useCallback(
    (scrollbarsRef) => {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.view);
      } else {
        forwardedRef(null);
      }
    },
    [forwardedRef]
  );

  return (
    <Scrollbars ref={refSetter} style={{ ...style, overflow: "hidden" }} onScroll={onScroll}>
      {children}
    </Scrollbars>
  );
};

const CustomScrollbarsVirtualList = forwardRef((props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />);

interface VirtualListProps {
  items: any;
}

export const VirtualList = memo(({ items }: VirtualListProps) => {
  const cart = useAppSelector((store) => store.cart.items);
  const listRef = createRef<any>();
  const outerRef = createRef<any>();

  const rowHeights = new Array(items.length + 1).fill(true).map((el, i) => (i === 0 ? ITEM_SIZE + TOOLBAR_SIZE : ITEM_SIZE));

  const getItemSize = (index: number) => rowHeights[index];

  return (
    <div
      css={css`
        height: calc(100vh - ${cart.length ? 183 : 121}px);
      `}
    >
      <AutoSizer>
        {({ height, width }) =>
          items.length ? (
            <VariableSizeList
              ref={listRef}
              className="List"
              height={height}
              itemCount={items.length}
              itemSize={getItemSize}
              width={width}
              outerElementType={CustomScrollbarsVirtualList}
              innerElementType={innerElementType}
              itemData={items}
              outerRef={outerRef}
            >
              {Row}
            </VariableSizeList>
          ) : (
            <EmptyList height={height} width={width} />
          )
        }
      </AutoSizer>
    </div>
  );
});

type EmptyListProps = {
  width: number;
  height: number;
};

const EmptyList: FunctionComponent<EmptyListProps> = ({ width, height }) => {
  return (
    <div
      css={css`
        width: ${width}px;
        height: ${height}px;
        padding: 20px;
        font-size: 14px;
        text-align: center;
      `}
    >
      Nenhum produto
    </div>
  );
};

// Toolbar
const Toolbar = ({ items }: any) => {
  return (
    <div
      css={css`
        // Category toolbar
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--color-black);
        padding: 16px 26px 8px;
        height: ${TOOLBAR_SIZE}px;
      `}
    >
      <div
        css={css`
          // Product counter
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
        `}
      >
        {items.length} produtos
      </div>
      <div
        css={css`
          // Ordenar
          display: flex;
          font-weight: normal;
          font-size: 11.5px;
          color: var(--color-black);
          align-items: center;
          justify-content: center;
          border: 1px solid var(--color-line);
          border-radius: 42px;
          padding: 7px 10px;
        `}
        onClick={() => {
          toast(({ closeToast }) => <SortBy close={closeToast} />, {
            transition: Slide,
            // onClose: () => (toastId.current = null),
            style: {
              minHeight: 75,
              maxHeight: "100%",
              borderRadius: 0,
              marginBottom: 0,
              padding: 0,
              background: "transparent",
              height: "100%",
              boxShadow: "none",
            },
          });
        }}
      >
        <span
          css={css`
            margin-right: 5px;
            user-select: none;
          `}
        >
          Ordenar
        </span>
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.22157 5.00707C4.4464 5.2319 4.81092 5.2319 5.03575 5.00707L8.69958 1.34324C8.92441 1.11841 8.92441 0.753891 8.69958 0.529061C8.47475 0.30423 8.11022 0.30423 7.88539 0.529061L4.62866 3.78579L1.37193 0.529061C1.1471 0.30423 0.782578 0.30423 0.557747 0.529061C0.332917 0.753891 0.332917 1.11841 0.557747 1.34324L4.22157 5.00707ZM4.05295 3.71426V4.59998H5.20438V3.71426H4.05295Z"
            fill="#A9A9A9"
          />
        </svg>
      </div>
    </div>
  );
};

type SortByProps = {
  close: any;
};

const SortBy: FunctionComponent<SortByProps> = ({ close }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        margin: -6px;
      `}
    >
      <div
        css={css`
          // Blocker
          width: 100%;
          height: 100%;
          position: absolute;
          /* background-color: rgba(0, 0, 0, 0.1); */
        `}
        onClick={() => close()}
      ></div>
      <div
        css={css`
          // Container
          background-color: #fff;
          height: 40%;
          margin-top: auto;
          z-index: 1;
          padding: 20px;
          /* border-top: 1px solid var(--color-line); */
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.51);
        `}
      >
        <Title>Ordenar</Title>
        <SortByOption name="NameAsc" close={close}>
          Nome A-Z
        </SortByOption>
        <SortByOption name="NameDesc" close={close}>
          Nome Z-A
        </SortByOption>
        <SortByOption name="PriceAsc" close={close}>
          Menor preço
        </SortByOption>
        <SortByOption name="PriceDesc" close={close}>
          Maior preço
        </SortByOption>
      </div>
    </div>
  );
};

type SortByOptionProps = {
  name: string;
  close: any;
};

const SortByOption: FunctionComponent<SortByOptionProps> = ({ children, name, close }) => {
  const dispatch = useAppDispatch();
  const activeSortBy = useAppSelector((store) => store.catalog.sortBy);

  return (
    <div
      css={css`
        margin: 0 -20px;
        border-top: 1px solid var(--color-line);
        padding: 20px;
        color: var(--color-black);

        &:last-child {
          border-bottom: 1px solid var(--color-line);
        }

        ${name === activeSortBy &&
        css`
          border-left: 6px solid var(--color-primary);
        `}
      `}
      onClick={() => {
        // Set sort by
        dispatch(setSortBy(name));

        // Close sort by toastify
        close();
      }}
    >
      {children}
    </div>
  );
};

// Row - Product card
const Row = ({ index, style, data }: any) => {
  const dispatch = useAppDispatch();
  const item = data[index];
  return (
    <div
      css={css`
        // Product card wrapper
      `}
      style={{
        ...style,
        // top: `${parseFloat(style.top) + PADDING_SIZE}px`,
      }}
    >
      {index === 0 && <Toolbar items={data} />}
      <div
        css={css`
          // Product card
          margin: 7px 14px;
          background-color: #fff;
          border: 1px solid var(--color-line);
          border-radius: 8px;
          padding: 16px;
          display: flex;
        `}
        onClick={() => {
          // Reset sides to add to cart
          dispatch(resetSides());

          // Set active product
          dispatch(setActiveProduct({ qty: 1, removeIngredients: [], ...item }));
          // Open product overlay
          dispatch(
            openOverlay({
              component: "Product",
              url: `/product/${item.id}`,
            })
          );
        }}
      >
        <Img alt={item?.name} src={item?.thumbnail} width={67} height={67} />
        <div
          css={css`
            // Product info wrapper
            flex: 1;
            display: inline-flex;
            flex-direction: column;
            padding-left: 17px;
          `}
        >
          <div
            css={css`
              // Product name
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              color: var(--color-black);
              overflow: hidden;
              max-height: 17px;
              margin-bottom: 2px;
            `}
          >
            {item.name}
          </div>
          <div
            css={css`
              // Product description
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: var(--color-black);
              max-height: 28px; /* fallback */
              overflow: hidden;
            `}
          >
            {item.description}
          </div>
          <div
            css={css`
              // Product price
              margin-top: auto;
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
            `}
          >
            {formatCurrency(item.price)}
          </div>
        </div>
      </div>
    </div>
  );
};

// Inner element type for virtual list
const innerElementType = forwardRef(({ style, ...rest }: any, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...style,
        // height: `${parseFloat(style.height) + PADDING_SIZE * 2}px`,
        // height: `${parseFloat(style.height) + PADDING_SIZE}px`,
        height: `${parseFloat(style.height) + 14}px`,
      }}
      {...rest}
    />
  );
});

export default Category;
