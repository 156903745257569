import { css } from "@emotion/react";
import { useState } from "react";
import { useParams } from "react-router";

const Frame = () => {
  const { src }: any = useParams();

  const urlParams = window.location.search;

  const [width, setWidth] = useState(348);
  const [height, setHeight] = useState(700);
  const [bgColor, setBgColor] = useState("#434343");

  const [widthDrag, setWidthDrag] = useState(false);
  const [heightDrag, setHeightDrag] = useState(false);

  const CONTROL_WIDTH = 300;

  const clientWidth = window.innerWidth || document.documentElement.clientWidth;
  const clientHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
  ];

  const getColorForPercentage = function (pct: any) {
    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
    // or output as hex if preferred
  };

  const getSrcUrl = () => {
    switch (src) {
      case "heroku":
        return "https://ouimenu.herokuapp.com/";
      default:
        return `//${window.location.host}`;
    }
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: ${bgColor};
      `}
    >
      <iframe
        css={css`
          z-index: 1;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
        `}
        height={height}
        width={width}
        title="Oui Menu"
        src={`${getSrcUrl()}${urlParams}`}
      >
        frame
      </iframe>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          position: fixed;
          bottom: 0;
          right: 0;
          user-select: none;
          width: ${CONTROL_WIDTH}px;
          background-color: #eee;
          height: 80px;
          cursor: col-resize;
          border-radius: 8px;
          overflow: hidden;
          opacity: 0.5;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 1;
          }
          div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            height: 100%;

            &:first-of-type {
              border-bottom: 1px solid #333;
              &:after {
                content: "";
                position: absolute;
                height: 100%;
                background-color: ${getColorForPercentage(
                  (width * 100) / clientWidth / 100
                )};
                width: ${(width * 100) / clientWidth}%;
                left: 0;
                z-index: -1;
                opacity: 0.7;
              }
            }

            &:last-of-type {
              border-bottom: 1px solid #333;
              &:after {
                content: "";
                position: absolute;
                height: 100%;
                background-color: ${getColorForPercentage(
                  (height * 100) / clientHeight / 100
                )};
                width: ${(height * 100) / clientHeight}%;
                left: 0;
                z-index: -1;
                opacity: 0.7;
              }
            }
          }
        `}
      >
        <input
          css={css`
            border: 0;
            border-bottom: 1px solid #333;
            height: 100%;
            position: relative;
            background-color: #eee;
            text-align: center;
            color: ${bgColor};
          `}
          type="text"
          onChange={(e) => {
            setBgColor(e.target.value);
          }}
          value={`${bgColor}`}
        />

        <div
          onMouseDown={(e) => {
            setWidthDrag(true);
            const leftOff = clientWidth - CONTROL_WIDTH;
            const length = e.clientX - leftOff;
            const percent = length / (CONTROL_WIDTH / 100);
            const size = Math.floor(clientWidth * (percent / 100));
            setWidth(size);
          }}
          onMouseUp={() => setWidthDrag(false)}
          onMouseLeave={() => setWidthDrag(false)}
          onMouseMove={(e) => {
            if (widthDrag) {
              const leftOff = clientWidth - CONTROL_WIDTH;
              const length = e.clientX - leftOff;
              const percent = length / (CONTROL_WIDTH / 100);
              const size = Math.floor(clientWidth * (percent / 100));
              setWidth(size);
            }
          }}
        >
          width: {width}px
        </div>
        <div
          onMouseDown={(e) => {
            setHeightDrag(true);
            const leftOff = clientWidth - CONTROL_WIDTH;
            const length = e.clientX - leftOff;
            const percent = length / (CONTROL_WIDTH / 100);
            const size = Math.floor(clientHeight * (percent / 100));
            setHeight(size);
          }}
          onMouseUp={() => setHeightDrag(false)}
          onMouseLeave={() => setHeightDrag(false)}
          onMouseMove={(e) => {
            if (heightDrag) {
              const leftOff = clientWidth - CONTROL_WIDTH;
              const length = e.clientX - leftOff;
              const percent = length / (CONTROL_WIDTH / 100);
              const size = Math.floor(clientHeight * (percent / 100));
              setHeight(size);
            }
          }}
        >
          height: {height}px
        </div>
      </div>
    </div>
  );
};

export default Frame;
