import { css } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import Placeholder, { getValue } from "./Placeholder";

type ImgProps = {
  src: string | undefined;
  alt: string | undefined;
  width?: number | string;
  height?: number | string;
  maxHeight?: number | string;
  borderRadius?: number;
  spinner?: boolean;
};

const Img: FunctionComponent<ImgProps> = ({ src, alt, width, height, maxHeight, borderRadius, spinner }, props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {!loaded && (spinner ? <Spinner /> : width && height && <Placeholder width={width} height={height} />)}
      <img
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        css={css`
          border-radius: ${getValue(borderRadius ? borderRadius : 8)};

          ${!loaded &&
          css`
            display: none;
          `}

          ${width &&
          !height &&
          css`
            width: ${getValue(width)};
            height: auto;
          `}
  
          ${!width &&
          height &&
          css`
            width: auto;
            height: ${getValue(height)};
          `}
  
          ${width &&
          height &&
          !maxHeight &&
          css`
            width: ${getValue(width)};
            height: ${getValue(height)};
          `}

          ${width &&
          height &&
          maxHeight &&
          css`
            width: ${getValue(width)};
            height: auto;
          `}

          ${maxHeight &&
          css`
            max-height: ${getValue(maxHeight)};
          `}
        `}
      />
    </div>
  );
};

const Spinner: FunctionComponent = () => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
        }
      `}
    >
      <svg
        viewBox="-4 -4 44 44"
        xmlns="http://www.w3.org/2000/svg"
        css={css`
          stroke: var(--color-primary);
        `}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="6">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
            </path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Img;
