import { css } from "@emotion/react";
import { Fragment, FunctionComponent } from "react";
import { Button } from "../FloatingButton";
import { useAppDispatch, useAppSelector } from "../hooks";
import { openOverlay } from "../store/slices/app";
import { calculatePrice, formatCurrency } from "../store/slices/catalog";

const OpenCartOverlay: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector((store) => store.cart.items);
  const subtotals = cart.reduce((acc, item) => {
    return acc + calculatePrice(item);
  }, 0);

  return (
    <Fragment>
      {cart.length ? (
        <Button>
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 24px;
            `}
            onClick={() => {
              // Open Cart overlay
              dispatch(
                openOverlay({
                  component: "Cart",
                  url: `/cart`,
                })
              );
            }}
          >
            <div
              css={css`
                flex: 1;
                text-align: center;
                display: flex;
                justify-content: center;
              `}
            >
              {/* <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 34px;
                  height: 22px;
                  background: rgba(255, 255, 255, 0.06);
                  border-radius: 78px;
                  font-weight: normal;
                  font-size: 12px;
                  margin-right: 13px;
                `}
              >
                {counter}
              </div> */}
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-right: 10px;
                `}
              >
                <div
                  css={css`
                    width: 100%;
                    text-align: center;
                  `}
                >
                  Ver carrinho
                </div>
              </div>

              <div
                css={css`
                  font-weight: normal;
                  font-size: 14.54px;
                  display: flex;
                  align-items: center;
                `}
              >
                {formatCurrency(subtotals)}
              </div>
            </div>

            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5303 12.5303C20.8232 12.2374 20.8232 11.7626 20.5303 11.4697L15.7574 6.6967C15.4645 6.40381 14.9896 6.40381 14.6967 6.6967C14.4038 6.98959 14.4038 7.46447 14.6967 7.75736L18.9393 12L14.6967 16.2426C14.4038 16.5355 14.4038 17.0104 14.6967 17.3033C14.9896 17.5962 15.4645 17.5962 15.7574 17.3033L20.5303 12.5303ZM5 12.75L20 12.75L20 11.25L5 11.25L5 12.75Z"
                fill="white"
              />
            </svg>
          </div>
        </Button>
      ) : null}
    </Fragment>
  );
};

export default OpenCartOverlay;
