import { FunctionComponent } from "react";
import styled from "@emotion/styled";
import Modal from "react-modal";

Modal.setAppElement("#root");

type ModalDecoratorProps = {
  isOpen: boolean;
  className?: string;
  contentLabel?: string;
  onRequestClose?: any;
  shouldCloseOnOverlayClick?: boolean;
};

export const ModalDecorator: FunctionComponent<ModalDecoratorProps> = ({
  className,
  isOpen,
  ...props
}) => {
  const [name] = (className && className.split(" ")) || [""];
  const styles = name
    ? {
        portalClassName: name,
        overlayClassName: `${name}__Overlay`,
        className: `${name}__Content`,
      }
    : {};

  return <Modal {...styles} {...props} isOpen={isOpen} />;
};

const StyledModal = styled(ModalDecorator)`
  &__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
  }

  &__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border: 1px solid #cccccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 90%;
  }
`;

export default StyledModal;
